html {
  box-sizing: border-box;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  ::-webkit-scrollbar {
    width: 6px;
    transition: width 300ms ease;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $slate-400;
  }
}
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: bold;
}

ul,
ol {
  list-style: none;
}
li {
  list-style: none;
}
iframe {
  border: 0;
}
canvas {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
[hidden] {
  display: none;
}

button,
input,
select,
textarea {
  margin: 0;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  appearance: button;
}
button,
select {
  text-transform: none;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
img,
video {
  max-width: 100%;
  height: auto;
}
audio,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img,
svg {
  vertical-align: middle;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0 0 1rem;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  td,
  th {
    padding: 0;
    &:not([align]) {
      text-align: inherit;
    }
  }
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
}

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main {
  display: block;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
textarea {
  resize: vertical;
  overflow: auto;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

code,
kbd,
pre,
samp {
  font-size: 1em;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}
