// Prefix for turacoui system as root
$turacoui: tu !default;

// Prefix for specific project used on all veriables
$project-prf: kbk !default; //start with capital letter
$prefix: --#{$project-prf}-#{$turacoui}- !default;
$class: --#{$project-prf} !default;

$font-family: "Turaco-regular";
//set colors
$slate-50: #f8fafc;
$slate-100: #f1f5f9;
$slate-200: #e2e8f0;
$slate-300: #cbd5e1;
$slate-400: #94a3b8;
$slate-500: #64748b;
$slate-600: #475569;
$slate-700: #334155;
$slate-800: #1e293b;
$slate-900: #0f172a;
$slate-950: #020617;

$flamenco-50: #fff8ed;
$flamenco-100: #ffefd4;
$flamenco-200: #ffdba9;
$flamenco-300: #ffc072;
$flamenco-400: #fe9b39;
$flamenco-500: #fd7e14;
$flamenco-600: #ee6108;
$flamenco-700: #c54909;
$flamenco-800: #9c3910;
$flamenco-900: #7e3110;
$flamenco-950: #441606;

$sunglow-50: #fefae8;
$sunglow-100: #fff5c2;
$sunglow-200: #ffe789;
$sunglow-300: #ffcb2d;
$sunglow-400: #fdb912;
$sunglow-500: #eca006;
$sunglow-600: #cc7902;
$sunglow-700: #a35405;
$sunglow-800: #86420d;
$sunglow-900: #723611;
$sunglow-950: #431b05;

$curious-blue-50: #eff9ff;
$curious-blue-100: #def1ff;
$curious-blue-200: #b6e5ff;
$curious-blue-300: #75d2ff;
$curious-blue-400: #2cbcff;
$curious-blue-500: #0099e8;
$curious-blue-600: #0082d4;
$curious-blue-700: #0067ab;
$curious-blue-800: #00578d;
$curious-blue-900: #064974;
$curious-blue-950: #042e4d;

$science-blue-50: #ecfbff;
$science-blue-100: #d4f3ff;
$science-blue-200: #b2edff;
$science-blue-300: #7de4ff;
$science-blue-400: #40d1ff;
$science-blue-500: #14b2ff;
$science-blue-600: #0092ff;
$science-blue-700: #007aff;
$science-blue-800: #0063cf;
$science-blue-900: #0854a0;
$science-blue-950: #0a3361;

$emerald-50: #ecfdf5;
$emerald-100: #d1fae5;
$emerald-200: #a7f3d0;
$emerald-300: #6ee7b7;
$emerald-400: #34d399;
$emerald-500: #10b981;
$emerald-600: #059669;
$emerald-700: #047857;
$emerald-800: #065f46;
$emerald-900: #064e3b;
$emerald-950: #022c22;

$indigo-50: #eef2ff;
$indigo-100: #e0e7ff;
$indigo-200: #c7d2fe;
$indigo-300: #a5b4fc;
$indigo-400: #818cf8;
$indigo-500: #6366f1;
$indigo-600: #4f46e5;
$indigo-700: #4338ca;
$indigo-800: #3730a3;
$indigo-900: #312e81;
$indigo-950: #1e1b4b;

$purple-50: #faf5ff;
$purple-100: #f3e8ff;
$purple-200: #e9d5ff;
$purple-300: #d8b4fe;
$purple-400: #c084fc;
$purple-500: #a855f7;
$purple-600: #9333ea;
$purple-700: #7e22ce;
$purple-800: #6b21a8;
$purple-900: #581c87;
$purple-950: #3b0764;

$fuchsia-50: #fdf4ff;
$fuchsia-100: #fae8ff;
$fuchsia-200: #f5d0fe;
$fuchsia-300: #f0abfc;
$fuchsia-400: #e879f9;
$fuchsia-500: #d946ef;
$fuchsia-600: #c026d3;
$fuchsia-700: #a21caf;
$fuchsia-800: #86198f;
$fuchsia-900: #701a75;
$fuchsia-950: #4a044e;

$pink-50: #fdf2f8;
$pink-100: #fce7f3;
$pink-200: #fbcfe8;
$pink-300: #f9a8d4;
$pink-400: #f472b6;
$pink-500: #ec4899;
$pink-600: #db2777;
$pink-700: #be185d;
$pink-800: #9d174d;
$pink-900: #831843;
$pink-950: #500724;

$rose-50: #fff1f2;
$rose-100: #ffe4e6;
$rose-200: #fecdd3;
$rose-300: #fda4af;
$rose-400: #fb7185;
$rose-500: #f43f5e;
$rose-600: #e11d48;
$rose-700: #be123c;
$rose-800: #9f1239;
$rose-900: #881337;
$rose-950: #4c0519;

// Colors
$orange: #fd7e14 !default;
$yellow: #ffa500 !default;
$green: #0f996d !default;
$turquoise: #20c997 !default;
$cyan: #0063cf !default;
$blue: #0099e8 !default;
$purple: #b86bff !default;
$red: #fc224a !default;

$main-color:#0082C8;

$blue-darker: #0067ab !default;
$blue-dark: #0082d4 !default;
$blue-root: #0099e8 !default;
$blue-light: #def1ff !default;
$blue-lighter: #eff9ff !default;

$black: $slate-950 !default;
$black-bis: $slate-900 !default;
$black-ter: $slate-800 !default;

$grey-darker: $slate-700 !default;
$grey-dark: $slate-600 !default;
$grey: $slate-500 !default;
$grey-light: $slate-400 !default;
$grey-lighter: $slate-300 !default;
$grey-lightest: $slate-200 !default;

$white-ter: $slate-100 !default;
$white-bis: $slate-50 !default;
$white: hsl(0, 0%, 100%) !default;

$breakpoints: (
//   'x-small':  ( min-width:  767px ),
  'small':  ( min-width:  576px ),
  'medium': ( min-width:  992px ),
  'large':  ( min-width: 1200px ),
  'x-large':  ( min-width: 1400px )
);

$breakpoint: (
  sm: 576px,
  md: 768px,
  xl: 1200px,
  xxl: 1400px,
);

$container-max-widths: (
//   'x-small': 540px,
  'small': 540px,
  'medium': 960px,
  'large': 1152px,
  'x-large': 1344px
);
//z-index
$zindex-dropdown: 2000 !default;
$zindex-sticky: 2010 !default;
$zindex-fixed: 2020 !default;
$zindex-offpage-backdrop: 2029 !default;
$zindex-offpage: 2030 !default;
$zindex-modal-backdrop: 2039 !default;
$zindex-modal: 2040 !default;
$zindex-popover: 2050 !default;
$zindex-tooltip: 2060 !default;
$zindex-toast: 2070 !default;

$space-o:0rem;
$space-xs:0.25rem;
$space-sm:0.375rem;
$space-md:0.5rem;
$space-lg:0.75rem;
$space-1:1rem;
$space-1x:1.25rem;
$space-1xl:1.5rem;
$space-2:2rem;
$space-2x:2.25rem;
$space-2xl:2.5rem;
$space-3:3rem;
$space-4:4rem;
$space-5:5rem;


$width-xs:20rem;
$width-sm:30rem;
$width-md:35rem;
$width-lg:40rem;
$width-xlg:48rem;
$width-xxlg:64rem;

$text-size-xs:0.75rem;
$text-size-md:0.875rem;
$text-size:1rem;
$text-size-lg:1.125rem;
$text-size-dxs:1.25rem;
$text-size-dsm:1.5rem;
$text-size-dmd:1.875rem;
$text-size-dlg:2.25rem;
$text-size-dxlg:3rem;
$text-size-dxxlg:3.75rem;

$radius-xs: 0.25rem !default;
$radius-sm: 0.75rem !default;
$radius: 0.625rem !default;
$radius-lg: 1rem !default;
$radius-round: 1000rem !default;

//innsidepad
$sidepad:3rem;

//smallform
$sm-height:1.75rem;
$sm-font-size:$text-size-md;
$sm-letterspacing:0;
$sm-padding:0.125rem 0.5rem;
$sm-radius: $radius-xs;

//smallform
$height:2.125rem;
$font-size:$text-size-md;
$letterspacing:0;
$padding:0.25rem .75rem;

//largeform
$lg-height:2.75rem;
$lg-font-size:$text-size;
$lg-letterspacing:0.0025em;
$lg-padding:0.25rem .75rem;
$lg-radius: $radius-sm;

//boxshadow
$boxshadow-fixed: 0.25em 0.25em 0.25em 0.25em rgba(0, 37, 99, 0.025);
$boxshadow-popover: 0 0.25em 0.75em 0.75em rgba(0, 37, 99, 0.075);
$boxshadow-tooltip: 0 0.25em 0.75em 0.5em rgba(0, 37, 99, 0.1);
$boxshadow-setle: 0 0 0.5em 0.25em rgba(0, 37, 99, 0.025);

:root {
  #{$prefix}body-background-color: saturate($cyan, 5%);
  #{$prefix}body-size: 16px;
  #{$prefix}body-min-width: 300px;
  #{$prefix}body-rendering: optimizeLegibility;
  #{$prefix}body-family: "Turaco-regular";
  #{$prefix}body-overflow-x: hidden;
  #{$prefix}body-overflow-y: auto;

  #{$prefix}body-color: #fff;
  #{$prefix}body-font-size: 1em;
  #{$prefix}body-weight: 400;
  #{$prefix}body-line-height: 1.5;

  #{$prefix}main:$cyan;
  #{$prefix}surface-mian:linear-gradient(16deg,#0099e8,#0063cf);
  #{$prefix}surface-chat:linear-gradient(36deg,$science-blue-600,$science-blue-500);
}

@at-root{
  $class: --kbk !default;
}