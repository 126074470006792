.kbk-table {
  &_hder {
    display: flex;
    justify-content: space-between;
    // align-items: flex-end;
  }
  .hder {
    display: block;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.8;
    letter-spacing: -0.25px;
  }
  &_ttl {
  }
  &_flt {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    gap: 0.25rem;

    margin-bottom: 1.25rem;
    > * {
      margin: 0;
      width: fit-content;
    }
  }
  &_body {
    border-top: 3px solid #f1f5f9;
    padding: 1rem;
  }
}
.kbk-table-dropdown {
  position: relative;
  &_list {
    background-color: #fff;
    // box-shadow: $boxshadow-tooltip;
    position: absolute;
    top: 20%;
    right: 100%;
    border-radius: $sm-radius;
    opacity: 0;
    pointer-events: none;
    max-height: fit-content;
    a,
    button {
      background-color: #fff !important;
      display: block;
      border: none;
      min-width: 4rem;
      font-size: 0.75rem !important;
      padding-inline: 1rem;
      &:hover {
        background-color: #eff9ff !important;
      }
    }
  }
  &:hover {
    button {
      // box-shadow: $boxshadow-tooltip;
    }
    .kbk-table-dropdown_list {
      // box-shadow: $boxshadow-tooltip;
      box-shadow: 2px 3px 5px rgba(#005fad, 0.12);
      z-index: $zindex-tooltip;
      top: 0;
      opacity: 1;
      pointer-events: all;
    }
  }
}
.kbk-table-responsive {
  width: 100%;
  $table-bg: #f1f5f9;
  $table-bg-light: #f1f5f975;
  $table-bg-lighter: #f1f5f995;
  $table-border: #e2e8f075;
  border-radius: 0.5rem;
  // overflow: hidden;
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1rem;
    th,
    td {
      white-space: wrap;
      vertical-align: middle;
      line-height: 1.2;
      font-size: 0.875rem;
      font-weight: 500;
      span.leb {
        display: block;
        font-size: 0.75rem;
        font-weight: 400;
        opacity: 0.5;
      }
    }
    thead {
      tr {
        background-color: $table-bg;
        border-bottom: 4px solid $table-border;
      }
      th {
        padding: 0.5rem 0.5rem;
        font-size: 0.75em;
        color: #64748b;
        text-transform: uppercase;
      }
    }
    tbody {
      tr {
        border-bottom: 2px solid $table-border;
        td {
          padding: 0.75rem 0.5rem;
        }
        &:nth-child(even) {
          background-color: $table-bg-light;
        }
        &:hover {
          background-color: $table-bg-lighter;
        }
      }
    }
  }
}

.kbk-updloadtable {
  .updload-list {
    &_header {
      font-weight: 600;

      .updload-list_item {
        background-color: #f1f5f925;
        border-radius: 0;
        .text {
          font-weight: 700;
        }
      }
    }
    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid #f1f5f9;
      border-radius: $radius-sm;
      background-color: #fff;
      gap: 2rem;
      &-dt {
        flex: 1;
        padding: 0.25rem 0.5rem;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 0.875rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
          display: flex;
          align-items: center;
        }
        > .kbk-btn-error {
          margin-left: 0.25rem;
        }
        .text {
          font-size: inherit;
          font-weight: inherit;
        }
        .nofile {
          .icon {
            background-color: #e2e8f0;
            img {
              width: 65%;
              object-fit: center;
              filter: grayscale(100%);
            }
          }
          .text {
            opacity: 0.5;
          }
        }
        .icon {
          width: 1.75rem;
          max-width: 1.75rem;
          aspect-ratio: 1;
          margin-right: 0.25rem;
          overflow: hidden;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          border-radius: $radius-xs;
          background-color: #ffefd4;
          img {
            width: 65%;
            object-fit: center;
          }
        }
      }
    }
    &_body {
      font-weight: 400;
      border-top: 3px solid #f1f5f9;
    }
  }
}

.tblist {
  .tblist-item {
    &:nth-last-child(-n + 4) {
      .kbk-table-dropdown {
        &_list {
          bottom: 20%;
        }
        &:hover {
          .kbk-table-dropdown_list {
            bottom: 0;
          }
        }
      }
    }
    &:nth-child(1),
    &:nth-child(2) {
      .kbk-table-dropdown {
        &_list {
          top: 20% !important;
          bottom: auto !important;
        }
        &:hover {
          .kbk-table-dropdown_list {
            top: 0 !important;
            bottom: auto !important;
          }
        }
      }
    }
  }
}
.tblist-chgt {
  min-height: fit-content !important;
  background-color: rgba($red, 5%);
  &_title {
    display: block;
    padding: 0.5rem 1rem;
    color: rgba($red, 80%);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8;
    letter-spacing: -0.25px;
  }
  .tblist-item_icon {
    width: 3.5rem;
    max-width: 3.5rem;
    aspect-ratio: 1;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 0.25rem;
    background-color: rgba($red, 5%) !important;
    svg {
      width: 65%;
      object-fit: contain;
      .cls-1 {
        fill-opacity: 0;
      }
      .cls-2 {
        fill: rgba($red, 85%) !important;
      }
    }
  }
  .tblist-item_xcn {
    .notring {
      width: 1.75rem;
      height: 1.75rem;
      flex-shrink: 0;
      transform-origin: top center;
      transform: rotate(12deg);
      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
        .cls-1 {
          fill: $red;
        }
        .cls-2 {
          fill: $red;
        }
        .cls-3 {
          fill: none;
        }
      }
      @include notifRing();
    }
  }
  .tblist-item {
    border: 1px solid rgba($red, 8%) !important;
    background-color: rgba($red, 8%) !important;
    &:nth-child(odd){
      border: 1px solid rgba($red, 1%) !important;
      background-color: rgba($red, 1%) !important;
    }
  }
}

.tbleFilter {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.5rem;
}

.kbk-empty {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &_cont {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &_ttl {
    display: block;
    text-align: center;
    color: #2020206e;
    font-weight: bold;
    text-align: left;
    font-size: 1em;
  }
}
