.track {
  padding: 1rem;
  background-color: $blue-lighter;
  border-radius: $radius;
  &-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  &-icon {
    height: 2.25rem;
    aspect-ratio: 1;
    background-color: #fff;
    // border: 1px solid rgba($blue-root,.2);
    border-radius: $radius-xs;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      aspect-ratio: 1;
      height: 65%;
      object-fit: contain;
    }
  }
  &-profile {
    height: 3.5rem;
    aspect-ratio: 1;
    border: 4px solid $blue-light;
    border-radius: $radius-sm;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-title {
    display: block;
    font-weight: 600;
    font-size: 0.875rem;
    margin: 0;
  }
  &-subtitle {
    display: block;
    font-size: 0.75rem;
    opacity: 0.75;
    font-weight: 400;
    line-height: 1;
    display: block;
    // color: $blue-dark;
  }
  &-user {
    display: block;
    font-weight: 600;
    font-size: 1.125rem;
  }
  &-usersub {
    display: block;
    font-weight: 600;
    font-size: 0.875rem;
    opacity: 0.5;
  }
}
// .track-grf{
//   height: 4rem;
//   min-width: 4rem;
//   border-radius: $radius;
// }
.trackrepo {
  margin-top: 0.5rem;
  padding: 1rem;
  background-color: #ecfdf5;
  border: 1px solid #a7f3d0;
  border-radius: $radius;
  p {
    display: block;
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    color: #475569;
    strong {
      color: #059669;
    }
  }
}
.trackrepo-pending {
  background-color: #faf5ff;
  border: 1px solid #e9d5ff;
  p {
    strong {
      color: #9333ea;
    }
  }
}
.trackrepo-rejected {
  background-color: #fff1f2;
  border: 1px solid #fecdd3;
  p {
    strong {
      color: #e11d48;
    }
  }
}
.trackrepo-warning {
  background-color: #fff8ed;
  border: 1px solid #ffdba9;
  p {
    strong {
      color: #ee6108;
    }
  }
}
.track-o {
  background-color: #ffefd4;
}
.track-p {
  background-color: #f3e8ff;
}
.track-b {
  background-color: #e0e7ff;
}
.track-g {
  background-color: #d1fae5;
}

.trackcard-body {
  margin-top: 1rem;
  padding: 2.5rem;
  // background-color: #eff9ff;
  // border: 1px solid #b6e5ff;
  border-radius: $radius;
  &-content {
  }
  &-stgz {
  }
}

.stg-list {
  $pos-x: 1.5rem;
  $poz-y: 1.5rem;
  $size-bb: 1.25rem;
  margin-top: 1rem;
  .stg-item {
    position: relative;
    padding: $pos-x $poz-y;
    &::before {
      content: "";
      position: absolute;
      left: calc($pos-x - calc($size-bb / 2));
      top: calc(calc($poz-y + 0rem) - 2px);
      display: block;
      width: $size-bb;
      aspect-ratio: 1;
      border-radius: $radius-sm;
      border: 3px solid $blue-light;
      background: $blue-root url("/assets/ikons/colored/ikon-checkmark.svg")
        no-repeat;
      background-position: center;
      background-size: 75%;
      z-index: 2;
    }
    &::after {
      content: "";
      position: absolute;
      left: calc($pos-x - 0.5px);
      transform: translateX(-50%);
      top: 0;
      display: block;
      width: 1px;
      height: 100%;
      border-radius: $radius-sm;
      background-color: $blue-root;
      z-index: 1;
    }
    &:last-child {
      &::after {
        height: calc($poz-y + 2px);
      }
    }
    &:first-child {
      &::after {
        top: calc(calc($poz-y + 0rem) + 2px);
        height: calc(100% - calc($poz-y + 2px));
      }
    }
    .stg-crd {
      padding: 0 1.5rem 0;
      &-num {
        display: block;
        font-weight: 500;
        font-size: 0.785rem;
        color: $blue-dark;
        text-transform: uppercase;
      }
      &-title {
        display: block;
        font-weight: 600;
        font-size: 1.25rem;
        margin-top: 0.375rem;
      }
      &-info {
        display: block;
        font-weight: 400;
        font-size: 0.875rem;
        opacity: 0.5;
      }
    }
  }
  .stg-done {
    &::before {
      border-radius: $radius-sm;
      border-color: $emerald-100;
      background: $emerald-500 url("/assets/ikons/colored/ikon-checkmark.svg")
        no-repeat;
      background-position: center;
      background-size: 75%;
    }
    &::after {
      left: calc($pos-x - 1px);
      background-color: $emerald-500;
    }
    .stg-crd {
      padding: 0 1.5rem 0;
      &-num {
        color: $emerald-600;
      }
    }
  }
  .stg-pending {
    &::before {
      border-radius: $radius-sm;
      border-color: $slate-100;
      background: $slate-200;
    }
    &::after {
      left: calc($pos-x - 1px);
      background-color: $slate-200;
    }
    .stg-crd {
      padding: 0 1.5rem 0;
      &-num {
        color: $slate-400;
      }
    }
  }
  .stg-active {
    background-color: $indigo-50;
    border: 1px solid $indigo-100;
    border-radius: 0.625rem;
    &::before {
      border-radius: $radius-sm;
      border-color: $indigo-200;
      border-style: dotted;
      background: $indigo-500;
      animation: activerod 1.52s ease-in-out infinite;
      transform-origin: center;
    }
    &::after {
      left: calc($pos-x - 2px);
      background-color: $indigo-500;
    }
    .stg-crd {
      padding: 0 1.5rem 0;
      &-num {
        color: $indigo-600;
      }
    }
    @keyframes activerod {
      to {
        transform: rotate(360deg);
      }
    }
  }
}
.bul-hdr {
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
  color: inherit;
}
.bul-list {
  &-item {
  }
  &-info {
    font-size: 0.875rem;
    position: relative;
    padding-left: 1.25rem;
    display: block;
    line-height: 1.75;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      margin-right: 0.25rem;
      width: 1.25rem;
      aspect-ratio: 1;
      border-radius: $radius-sm;
      border: 3px solid $blue-light;
      background: $blue-root url("/assets/ikons/colored/ikon-checkmark.svg")
        no-repeat;
      background-position: center;
      background-size: 75%;
      z-index: 2;
    }
  }
}
.bul-perm {
  .bul-hdr {
    color: #059669;
  }
  .bul-list {
    &-info {
      &::before {
        border: 2px solid #ecfdf5;
        background: #10b981 url("/assets/ikons/colored/ikon-checkmark.svg")
          no-repeat;
        background-position: center;
        background-size: 75%;
      }
    }
  }
}
.bul-cond {
  .bul-hdr {
    color: #ee6108;
  }
  .bul-list {
    &-info {
      &::before {
        border: 2px solid #fff8ed;
        background: #fd7e14 url("/assets/ikons/colored/ikon-cond.svg") no-repeat;
        background-position: center;
        background-size: 75%;
      }
    }
  }
}
.bul-proh {
  .bul-hdr {
    color: #e11d48;
  }
  .bul-list {
    &-info {
      &::before {
        border: 2px solid #fff1f2;
        background: #f43f5e url("/assets/ikons/colored/ikon-cancel.svg")
          no-repeat;
        background-position: center;
        background-size: 75%;
      }
    }
  }
}
.rnd-crd {
  padding: 1rem;
  background-color: $blue-lighter;
  border: 1px solid $blue-light;
  border-radius: $radius;
  h3 {
    display: block;
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
    position: relative;
    margin-bottom: 1rem;
    &::before {
      content: "";
      display: inline-block;
      width: 1rem;
      aspect-ratio: 1;
      border-radius: $radius-xs;
      // border: 1px solid $blue-root
      margin-right: 0.35rem;
      z-index: 2;
    }
  }
  label {
    font-size: 0.75rem;
    font-weight: 500;
  }
  &_info {
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;
  }
  &_title {
    display: block;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1;
  }
  &_zn {
    background-color: #fff8ed;
    border: 1px solid #ffdba9;
    padding: 0.5rem 1rem;
    border-radius: $radius-xs;
    width: fit-content;
    min-width: 8rem;
    .rnd-crd_title {
      font-size: 0.875rem;
    }
    .rnd-crd_hd {
      font-size: 1.875rem;
      font-weight: 600;
      line-height: 1;
    }
  }
  .loc {
    &::before {
      background: transparent url("/assets/ikons/colored/ikon-pin.svg")
        no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }
  .usz {
    &::before {
      background: transparent url("/assets/ikons/colored/ikon-use.svg")
        no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }
  .zng {
    &::before {
      background: transparent url("/assets/ikons/colored/ikon-map.svg")
        no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }
}

//stages
.accordion-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .accordion-item {
    $arrow: #94a3b8;
    $arrow-active: #2cbcff;
    flex: 1;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    &:hover {
      border: 1px solid #def1ff;
      box-shadow: 0 0 0.5em 0.25em rgba(0, 37, 99, 0.025);
    }
    .accordion-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem;
      color: #000;
      font-size: 1.125rem;
      line-height: 1.2;
      font-weight: 500;
      cursor: pointer;
      .more-btn {
        position: relative;
        width: 1.25rem;
        aspect-ratio: 1;
        border-radius: 0.25rem;
        border: 2px solid $arrow;
        flex-shrink: 0;
        &::after {
          content: "";
          display: block;
          width: 0.5rem;
          aspect-ratio: 1;
          border-radius: 2px;
          border: none;
          border-left: 2px solid $arrow;
          border-bottom: 2px solid $arrow;
          position: absolute;
          transform-origin: center;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          transition: transform 300ms ease-in-out;
        }
      }
    }
    .accordion-content {
      padding: 0 1rem;
      color: #000;
      border-top: 1px solid #f8fafc;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 400;
      max-height: 0px;
      opacity: 0;
      overflow: hidden;
      p {
        opacity: 0.75;
        margin: 0;
      }
    }
    &:has(input[type="checkbox"]:checked) {
      border: 1px solid #def1ff;
      box-shadow: 0 0 0.5em 0.25em rgba(0, 37, 99, 0.025);
      .more-btn {
        border-color: $arrow-active;
        &::after {
          transform: translate(-50%, -20%) rotate(135deg);
          border-left-color: $arrow-active;
          border-bottom-color: $arrow-active;
        }
      }
      .accordion-content {
        max-height: fit-content;
        opacity: 1;
        padding: 1rem;
      }
    }
  }
}
.accord-tip {
  .accordion-item {
    border: 1px solid #def1ff;
    .accordion-btn {
      padding: 1rem 1.5rem;
    }
  }
}

//plot
.plot-card {
  padding: 1.25rem;
  border: 1px solid #f1f5f9;
  border-radius: $radius-sm;
  &_hder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      font-size: 1rem;
    }
  }
  &_body {
    background-color: #f1f5f9;
    border-radius: $radius-xs;
    margin: 0.5rem 0;
    width: fit-content;
    aspect-ratio: 16/9;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .hld {
      width: 50%;
      height: 50%;
      object-fit: contain;
      opacity: 0.15;
    }
  }
  &_footer {
    margin-top: 1rem;
    p {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.45;
      margin-bottom: 0.5rem;
      label {
        font-size: 0.875em;
        font-weight: 400;
        line-height: 1;
      }
    }
  }
}
