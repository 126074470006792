input,
select,
textarea {
  background-color: $input-background-color;
  border-color: $input-border-color;
  border-radius: $sm-radius;
  color: $input-color;
  height: $height;
  font-size: $font-size;
  letter-spacing: $letterspacing;
  padding: $padding;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  &::placeholder {
    color: $input-placeholder-color;
  }
  &:focus,
  &:active {
    border-color: $input-focus-border-color;
  }
  &[disabled],
  fieldset[disabled] {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
    box-shadow: none;
    color: $input-disabled-color;
  }
  &.fsm {
    height: $sm-height;
    font-size: $sm-font-size;
    letter-spacing: $sm-letterspacing;
    padding: $sm-padding;
    border-radius: $sm-radius;
  }
  &.flg {
    height: $lg-height;
    font-size: $lg-font-size;
    letter-spacing: $lg-letterspacing;
    padding: $lg-padding;
    border-radius: $sm-radius;
  }
  &.flght {
    background-color: hsla(216, 100%, 35%, 0.4);
    border-color: hsla(216, 100%, 35%, 0.2);
    color: #fff;
    &::placeholder {
      color: #ffffff83;
    }
    &:focus,
    &:active {
      border-color: #ffffff83;
    }
  }
}
select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right 0.5em;
  background-size: 1em;
  padding-right: 2em;
  &.fsm{
    padding-right: 2em;
  }
}
textarea {
  resize: vertical;
  min-height: 6em;
}
.form-checkbox {
  display: inline-block;
  width: 1.25rem;
  aspect-ratio: 1;
  border: 1px solid $blue-light;
  background-color: $blue-lighter;
  border-radius: 0.25rem;
  margin: 0;
  position: relative;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: url("/assets/ikons/colored/ikon-checkmark.svg") no-repeat;
    background-position: center;
    background-size: 0%;
  }
  input[type="checkbox"] {
    display: none;
  }
  &:has(input[type="checkbox"]:checked) {
    border: 1px solid $blue-light;
    background-color: $blue-root;
    &::before {
      background-position: center;
      background-size: 100%;
    }
  }
}
.form-checkbox-dft{
  width: 1rem;
  aspect-ratio: 1;
  border: 1px solid #cbd5e1;
  background-color: #f8fafc;
}
.form-incheckbox {
  .checkbox {
    display: flex;
    // align-items: center;
    gap: 0.5rem;
    margin: 0;
    position: relative;
    cursor: pointer;
    input[type="checkbox"] {
      display: none;
    }
    &_box {
      display: inline-block;
      height: 1.25rem;
      aspect-ratio: 1;
      flex-shrink: 0;
      margin-top: 0.125rem;
      border: 1px solid #cbd5e1;
      background-color: #f1f5f9;
      border-radius: 0.25rem;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: url("/assets/ikons/colored/ikon-checkmark.svg") no-repeat;
        background-position: center;
        background-size: 0%;
      }
    }
    &_txt {
      display: inline-block;
      font-size: 0.875rem;
      color: #334155;
    }
  }
  &:has(input[type="checkbox"]:checked) {
    .checkbox {
      &_box {
        border: 1px solid $blue-light;
        background-color: $blue-root;
        &::before {
          background-position: center;
          background-size: 100%;
        }
      }
    }
  }
}
.form-input {
  width: 100%;
  margin-bottom: 0.75rem;
  > div {
    width: 100%;
    input,
    select,
    textarea {
      width: 100%;
      // font-size: 1em;
      font-weight: 400;
    }
  }
  &_search {
    display: flex;
    align-items: center;
    line-height: 1.5;
    position: relative;
    vertical-align: bottom;
    background-color: transparent;
    border: 1px solid;
    border-color: #cbd5e1;
    border-radius: 0.25rem;
    color: #475569;
    height: 2.125rem;
    font-size: 0.875rem;
    letter-spacing: 0;
    overflow: hidden;
    input {
      border: none;
      background-color: transparent;
      padding: 0.25rem 0.75rem ;
      padding-right: 0;
    }
    .btn {
      height: calc(100% - 0px);
      aspect-ratio: 1;
      flex-shrink: 0;
      background-color: transparent;
      border: none;
      color: #0099e8;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: $blue-lighter;
        // border-color: $blue-light;
        color: $blue-root;
      }
      svg {
        width: 75%;
        height: 75%;
        object-fit: contain;
        fill: #0099e8;
        path {
          // fill: #0099e8;
          opacity: 1;
        }
      }
    }
    &:focus-within {
      border-color: $input-focus-border-color;
      .btn {
        background-color: $blue-lighter;
      }
    }
    &.fsm {
      height: $sm-height;
      font-size: $sm-font-size;
      letter-spacing: $sm-letterspacing;
      padding: 0;
      border-radius: $sm-radius;
      input{
        height: inherit;
        font-size: inherit;
        letter-spacing: inherit;
        padding: $sm-padding;
        border-radius: inherit;
      }
    }
    &.fsm {
      height: $lg-height;
      font-size: $lg-font-size;
      letter-spacing: $lg-letterspacing;
      padding: 0;
      border-radius: $lg-radius;
      input{
        height: inherit;
        font-size: inherit;
        letter-spacing: inherit;
        padding: $lg-padding;
        border-radius: inherit;
      }
    }
  }
}
.form-uploadfile {
  line-height: 1;
  height: $height;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-radius: $sm-radius;
  border: 1px solid $input-border-color;
  display: flex;
  align-items: center;
  &-btn {
    padding: 0.35rem 0.75rem;
    width: fit-content;
    height: 100%;
    margin-inline: 0.2rem;
    font-size: 1em;
    border-radius: $sm-radius;
    background-color: $grey-lightest;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $grey-lighter;
    }
  }
  &-txt {
    font-weight: 500;
    font-size: 1em;
    opacity: 0.75;
    margin-left: 0.5em;
  }
}
.form-uploaddocs {
  $wsize: 15rem;
  &-btn {
    height: calc($height * 1);
    width: $wsize;
    border-radius: $sm-radius;
    border: 1px solid $input-border-color;
    border-radius: $sm-radius;
    background-color: $grey-lightest;
    display: flex;
    align-items: center;
    font-size: 1rem;
    gap: 0.35rem;
    padding-inline: 2.5rem 3rem;
    cursor: pointer;
    svg {
      width: 1.25rem;
      aspect-ratio: 1;
      fill: #64748b;
      margin-left: 0.25rem;
      path {
        fill: inherit;
      }
    }
  }
  &-load {
    width: $wsize;
    // margin-top: 1rem;
    padding: 0.125rem 0.25rem 0.25rem;
    // background-color: #f8fafc;
    position: relative;
    overflow: hidden;
    text-align: right;
  }
  &-text {
    font-size: 0.875rem;
    color: $blue-root;
  }
  &-loader {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50%;
    background-color: $blue-root;
  }
}
.inputgrp {
  display: flex;
  align-items: center;
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.form-set {
  display: flex;
  flex-wrap: wrap;
  // gap-row: .75rem;
  column-gap: 1.25rem;
  // > * {
  //   flex: 0 0 auto;
  //   width: calc((100% / 2) - 0.75rem);
  // }
  > * {
    flex: 1;
    min-width: 16rem;
  }
}
.form-short{
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.25rem;
  > * {
    flex: 1;
    min-width: 10rem;
  }
}
.verform {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  .flg {
    width: $lg-height;
    text-align: center;
  }
}
.form-out {
  // margin-bottom: 0.25rem;
  // border: 1px solid rgba(0, 0, 0, 0.062);
  padding: 0.25rem 0.5rem;
  span {
    display: block;
    color: $input-color;
    color: #0f172a;
    font-size: $font-size;
    font-size: 1rem;
    letter-spacing: $letterspacing;
    font-weight: 600;
    line-height: 1.5;
    min-height: $height;
  }
  &_txtarea{
    height: auto !important;
  }
}
.kbk-wrap-4{
  &:has(.form-out){
    align-items: flex-start !important;
  }
}

.uploaded-content {
  padding: 1rem;
  min-width: 25rem;
}
.uploaded-list {
  margin-top: 1rem;
  .uploaded-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.375rem;
    background-color: #eff9ff;
    padding: 0.375rem;
    margin-bottom: 0.25rem;
    border-radius: $sm-radius;
    img {
      width: 1.25rem;
      aspect-ratio: 1;
      margin-right: 0.5rem;
    }
    span {
      font-size: 0.875rem;
    }
    .xtions {
      margin-left: 1rem;
      justify-self: end;
      .kbk-link {
        height: 1.5rem;
        aspect-ratio: 1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: 1px solid #b6e5ff;
        margin-inline: 0.25rem;
        opacity: 0.75;
        svg {
          width: 75%;
          height: 75%;
          object-fit: contain;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}



@media only screen and (max-device-width: 768px) {
  .form-set {
    > * {
      flex: 0 0 16rem;
    }
  }
}