.pagnation {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  color: #64748b;
  &-item {
    width: fit-content;
  }
  &-dt {
    .ent {
      padding: 0.375rem 0.75rem;
      border-radius: $sm-radius;
      background-color: #f1f5f9;
      margin-right: 0.25rem;
    }
    .cur {
      padding: 0.375rem 0.75rem;
      border-radius: $sm-radius;
      background-color: #f1f5f9;
      font-weight: 600;
      color: $blue-root;;
    }
  }
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  font-size: 1rem;
  .page-item {
    &:first-child {
      border-top-left-radius: $sm-radius;
      border-bottom-left-radius: $sm-radius;
    }
    &:last-child {
      border-top-right-radius: $sm-radius;
      border-bottom-right-radius: $sm-radius;
    }
  }
  .disabled {
    .page-link {
      pointer-events: none;
      color: #64748b;
      background-color: #f1f5f9;
      border-color: 1px solid #f1f5f9;
    }
  }
  .page-link {
    position: relative;
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 0.875em;
    // color: #64748b;
    background-color: $blue-lighter;
    border-color: $blue-lighter;
    color: $blue-root;
    &:hover {
      background-color: $blue-light;
      border-color: $blue-light;
      color: $blue-root;
    }
  }
}
