.modol-header {
  position: relative;
  padding: 2rem 2rem;
  .modol-title {
    font-size: 1.25rem;
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.125rem;
  }
  p {
    font-size: 1rem;
    opacity: 0.5;
  }
  .kbk-btn-close {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }
}
.modol-content {
  padding: 0.5rem 2rem 2rem;
  img {
    width: 1.25rem;
    aspect-ratio: 1;
    margin: 0.25rem;
  }
  .kbk-lst {
    margin: 0.5rem 0 0;
    span {
      margin-left: 0.5rem;
      font-weight: 500;
    }
  }
}
.modal {
  z-index: $zindex-modal;
}
