$bdg-color: #0067ab !default;
$bdg-background-color: $blue-light !default;
$bdg-border-color: $blue-light !default;
$bdg-border-width: 1px !default;
$bdg-padding: 0.35rem 0.65rem;
$bdg-padding-sm: 0.25rem 0.5rem;
//error
$bdg-reject-color: $rose-600 !default;
$bdg-reject-background-color: $rose-100 !default;
$bdg-reject-border-color: $rose-100 !default;
//warn
$bdg-wait-color: $sunglow-500 !default;
$bdg-wait-background-color: $sunglow-50 !default;
$bdg-wait-border-color: $sunglow-100 !default;
//success
$bdg-success-color: $emerald-600 !default;
$bdg-success-background-color: $emerald-100 !default;
$bdg-success-border-color: $emerald-100 !default;
//pending
$bdg-pending-color: $indigo-600 !default;
$bdg-pending-background-color: $indigo-100 !default;
$bdg-pending-border-color: $indigo-100 !default;

.bdg {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: $bdg-padding;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: $letterspacing;
  color: $bdg-color;
  background-color: $bdg-background-color;
  border-color: $bdg-border-color;
  border-radius: $radius-round;
  text-align: center;
  white-space: nowrap;
  margin: 0 0.25em;
  line-height: 1;
  &.bdg-sm {
    font-size: 0.65rem;
    font-weight: 600;
    font-family: "Turaco-semibold";
    padding: 0 ;
    padding-inline: 0.2em 0.35em;
    min-width: 0.9rem;
    max-width: 1.2rem;
    height: 0.9rem;
    flex-shrink: 0;
    vertical-align: middle;
    overflow: hidden;
  }
  &.bdg-lg {
    font-size: $lg-font-size;
    letter-spacing: $lg-letterspacing;
  }
  &.bdg-pend {
    color: $bdg-pending-color;
    background-color: $bdg-pending-background-color;
    border-color: $bdg-pending-border-color;
  }
  &.bdg-reject,
  &.bdg-delete {
    color: $bdg-reject-color;
    background-color: $bdg-reject-background-color;
    border-color: $bdg-reject-border-color;
  }
  &.bdg-wait {
    color: $bdg-wait-color;
    background-color: $bdg-wait-background-color;
    border-color: $bdg-wait-border-color;
  }
  &.bdg-complete,
  &.bdg-active {
    color: $bdg-success-color;
    background-color: $bdg-success-background-color;
    border-color: $bdg-success-border-color;
  }
  &.bdg-notif{
    border-radius: 0.25rem;
    color: $white;
    background-color: $red;
    border-color: $red;
  }
}

.bg-l-o {
  background-color: #ffefd4 !important;
}

.red-background {
  background-color: rgba($red, 5%);
  border: 1px solid rgba($red, 15%);
  .tblist-item_icon {
    background-color: rgba($red, 25%) !important;
    img {
      &.icon-error {
        display: block !important;
      }
      &.icon-dft,
      &.icon-wait,
      &.icon-done {
        display: none !important;
      }
    }
  }
  // .kbk-link-btn {
  //   border: 1px solid rgba($red, 15%) !important;
  //   background-color: rgba($red, 15%) !important;
  //   color: rgba($red, 100%) !important;
  // }
  .bdg {
    color: $bdg-reject-color !important;
    background-color: $bdg-reject-background-color !important;
    border-color: $bdg-reject-border-color !important;
  }
}

.yellow-background {
  background-color: rgba($yellow, 5%);
  border: 1px solid rgba($yellow, 15%);
  .tblist-item_icon {
    background-color: rgba($yellow, 25%) !important;
    img {
      &.icon-wait {
        display: block !important;
      }
      &.icon-dft,
      &.icon-error,
      &.icon-done {
        display: none !important;
      }
    }
  }
  // .kbk-link-btn {
  //   border: 1px solid rgba($yellow, 15%) !important;
  //   background-color: rgba($yellow, 15%) !important;
  //   color: rgba($yellow, 100%) !important;
  // }
  .bdg {
    color: $bdg-wait-color !important;
    background-color: $bdg-wait-background-color !important;
    border-color: $bdg-wait-border-color !important;
  }
}

.green-background {
  background-color: rgba($green, 5%);
  border: 1px solid rgba($green, 15%);
  .tblist-item_icon {
    background-color: rgba($green, 25%) !important;
    img {
      &.icon-done {
        display: block !important;
      }
      &.icon-dft,
      &.icon-error,
      &.icon-wait {
        display: none !important;
      }
    }
  }
  // .kbk-link-btn {
  //   border: 1px solid rgba($green, 15%) !important;
  //   background-color: rgba($green, 15%) !important;
  //   color: rgba($green, 100%) !important;
  // }
  .bdg {
    color: $bdg-success-color !important;
    background-color: $bdg-success-background-color !important;
    border-color: $bdg-success-border-color !important;
  }
}

.kbk-warn {
  display: flex;
  align-items: center;
  gap: 0.25em;
  // padding: 0.25rem 0.5rem;
  font-size: 0.65rem;
  font-weight: 600;
  color: $sunglow-600;
  // background-color: $sunglow-50;
  // border-color: $sunglow-50;
  text-align: center;
  border-radius: 0.2rem;
  img{
    width: 1.25em;
    object-fit: contain;
  }
}

.ikonring{
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  transform-origin: top center;
  transform: rotate(12deg);
  svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    .cls-1 {
      fill: $yellow;
    }
    .cls-2 {
      fill: $yellow;
    }
    .cls-3 {
      fill: none;
    }
  }
  @include notifRing();
}