@import "./../utilities/utilities";
.#{$class}-flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kbk-x {
  display: flex;
  justify-content: space-between;
  // align-items: center;
}
.kbk-x-s {
  display: flex;
  justify-content: flex-start;
  // align-items: center;
}
.kbk-x-e {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.kbk-x-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.kbk-aend {
  align-items: flex-end;
}
.kbk-ac {
  align-items: center;
}
.kbk-wrap,
.kbk-wrap-2,
.kbk-wrap-3,
.kbk-wrap-4 {
  display: flex;
}
.kbk-wrap {
  flex-wrap: wrap;
  gap: 0.5rem;
  > * {
    flex: 0 0 auto;
    width: auto;
  }
}
.kbk-wrap-2 {
  flex-wrap: wrap;
  gap: 1rem;
  > * {
    flex: 1 0 10rem;
    width: calc((100% / 2) - (1rem * 1));
  }
}
.kbk-wrap-3 {
  flex-wrap: wrap;
  gap: 0.5rem;
  > * {
    flex: 0 0 auto;
    width: calc((100% / 3) - (0.5rem * 1));
  }
}
.kbk-wrap-4 {
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 0.5rem;
  > * {
    flex: 0 0 auto;
    width: calc((100% / 4) - (0.5rem * 3));
  }
  > * {
    min-width: calc((100% / 4) - (0.5rem * 3));
    max-width: calc((100% / 2) - (0.5rem * 1));
    flex: 1;
  }
}
.sp-xs {
  gap: $space-xs;
}
.sp-sm {
  gap: $space-sm;
}
.sp-md {
  gap: $space-md;
}
.sp-lg {
  gap: $space-lg;
}
.sp-1 {
  gap: $space-1;
}
.sp-2 {
  gap: $space-2;
}
.t-uc {
  text-transform: uppercase;
}
.mt-md {
  margin-top: $space-2;
}
.hide {
  display: none;
}
.clear-p{
  padding: 0 !important;
}
.clear-m{
  margin: 0 !important;
}
.w-aut{
  width: auto !important;
}
.spc-cmb{
  display: flex;
  .clear-m{
    flex: 1;
  }
  .btns{
    flex: 1;
    margin-left: 1.5rem;
  }
}
hr {
  border-color: $grey-light;
  margin: 1rem 0;
}
.p-cont{
  padding: 1rem 1.5rem;
}
.load-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 15rem;
  .preload {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    $color: #e94e3d;
    .line-cont {
      width: 2rem;
      aspect-ratio: 1;
      border-radius: 50%;
      position: relative;
      span {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 4px solid;
        margin-bottom: 0.375rem;
        transform-origin: center;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        margin: 0;
        
        &.line1 {
          border-color: #75d2ff transparent #0099e8 transparent;
          animation: border-rot 1s linear infinite;
          z-index: 1;
        }
        @keyframes border-rot {
          0% {
            transform: translate(-50%, -50%) rotate(0deg);
            border-color: #fff #75d2ff #0099e8 #fff;
          }
          25% {
            transform: translate(-50%, -50%) rotate(-180deg);
            border-color: #75d2ff ;
          }
          50% {
            border-color: #75d2ff #fff #0099e8 #75d2ff;
          }
          75% {
            border-color: #0099e8;
          }
          100% {
            transform: translate(-50%, -50%) rotate(360deg);
            border-color: #fff ;
          }
        }
      }
    }
  }
}
