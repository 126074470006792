.mdc-snackbar{
  display: none;
  position: fixed;
  top: 0 !important;
  right: 0 !important;
  bottom: auto !important;
  left: auto !important;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.error-snackbar {
    max-width: max-content !important;
    color: white;
    --mdc-snackbar-container-color: #f43f5e;
    --mat-mdc-snack-bar-button-color: #ffffff;
    .mdc-snackbar__surface{
        background-color: var(--mdc-snackbar-container-color);
        color: var(--mat-mdc-snack-bar-button-color);
    }
    .mat-mdc-button{
        background-color: var(--mdc-snackbar-container-color);
        color: var(--mat-mdc-snack-bar-button-color);
        &:hover{
            background-color: #e11d48;
            color: var(--mat-mdc-snack-bar-button-color);
        }
    }
}
// .mat-mdc-simple-snack-bar {
//     display: flex;
// }
// .mat-mdc-snack-bar-handset, .mat-mdc-snack-bar-container, .mat-mdc-snack-bar-label {
//     flex: 1 1 auto;
// }
// .mat-mdc-snack-bar-container {
//     margin: 8px;
//     --mdc-snackbar-container-shape: 4px;
//     position: static;
// }