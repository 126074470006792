.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    background-color: #0099e8;
  }
  &::before {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    background-color: #fff;
  }
  .login-content {
    display: flex;
    position: relative;
    z-index: 5;
    width: 80vw;
    min-height: 200px;
    border-radius: 10px;
    contain: style;
    background: linear-gradient(to left, #fff 50%, #0099e8 50%);
    // border: 1px solid #007dbb;
    .illust,
    .dtials {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .illust {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .logo {
        height: 2rem;
        margin-bottom: 2rem;
      }
      .ill {
        height: 55vh;
        margin-right: 2rem;
        filter: drop-shadow(#042f4d21 0.5rem 0.5rem 20px);
      }
    }
    .form {
      min-width: 420px;
      padding: 2rem;
      &-header {
        margin-bottom: 2rem;
      }
      &-body {
        margin-bottom: 2rem;
      }
      &-input {
        margin-top: 0.5rem;
        width: 100%;
      }
      &-incheckbox {
        margin-bottom: 1rem;
      }
      &-footer {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.5rem;
        .kbk-btn {
          width: 100%;
        }
      }
    }
    .logmsg {
      min-width: 420px;
      padding: 4rem;
      display: flex;
      // justify-content: center;
      align-items: center;
      flex-direction: column;
      .logreg {
        width: 8rem;
        min-height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
          .cls-1,
          .cls-3 {
            fill: none;
          }
          .cls-2 {
            fill: $curious-blue-500;
            opacity: 0.15;
          }
          .cls-3 {
            stroke: $curious-blue-500;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
          }
        }
      }
      h2 {
        margin-bottom: 2rem;
      }
      p {
        text-align: center;
        font-size: 0.875rem;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        min-width: 4rem;
        margin-top: 2rem;
      }
    }
  }
}

@media screen and (max-width: 512px) {
  .login-container {
    &::after {
      width: 100%;
      background-color: #0099e8;
    }
    &::before {
      display: none;
    }
    .login-content {
      flex-direction: column;
      background: transparent;

      .illust {
        .ill {
          display: none;
        }
      }
      .form,
      .logmsg {
        min-width: 340px;
        background-color: #fff;
        border-radius: 10px;
      }
    }
  }
}
