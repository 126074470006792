$overlap: 9rem;
.inner-bg {
  background-color: #f8fafc;
  padding-bottom: 1rem;
}
.account-hder {
  background: var(--kbk-tu-surface-mian);
  &-content {
    position: relative;
    // z-index: 1;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("/bg.svg") no-repeat;
      background-size: 50%;
      background-position: right center;
      z-index: 0;
      mix-blend-mode: multiply;
      opacity: 0.25;
      pointer-events: none;
    }
  }
  .prim-nav {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem $overlap;
    .logo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 10rem;
      height: 3rem;
      aspect-ratio: 1;
      img {
        width: auto;
        height: 2rem;
        object-fit: contain;
      }
    }
    .main-nav {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 1rem;
      a {
        color: $curious-blue-100;
      }
      .main-nav-list {
        kbk-btn-close {
          display: none !important;
        }
      }
    }
    .mob-menu {
      display: none;
    }
  }
  .xtn-links {
    padding: 0.75rem 0 0.5rem;
    border-bottom: 3px solid #14b2ff4a;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-inline: 0.1rem;
      height: auto;
      font-size: 0.8rem;
      color: $sunglow-300;
      letter-spacing: 0;
      padding: 0.375rem 0.75rem;
      line-height: 100%;
      font-weight: 500;
      border-bottom: 1px solid transparent;
      position: relative;
      opacity: 1;
      cursor: pointer;
      &:hover{
        opacity: 0.85;
      }
      &.active {
        opacity: 0.85;
      }
    }
    span{
      display: block;
      width: 1px;
      height: 1.125rem;
      background-color: $sunglow-300;
    }
  }
}

.usermenu {
  $colorLink: $science-blue-900;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  &-list {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  &-item {
  }
  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    // background-color: $colorLink;
    border: 1px solid $curious-blue-400;
    border-radius: 0.25rem;
    position: relative;
    cursor: pointer;
    svg {
      fill: #b6e5ff;
      width: 65%;
      height: 65%;
      object-fit: contain;
    }
    &:hover {
      background-color: $curious-blue-400;
    }
    .badge-notify {
      $size: 0.875rem;
      display: block;
      width: $size;
      aspect-ratio: 1;
      border-radius: 50%;
      background-color: $red;
      border: 2px solid #0063cf;
      position: absolute;
      top: calc(($size / 3) * -1);
      right: calc(($size / 3) * -1);
    }
  }
  .userprofile {
    position: relative;
    border-left: 1px solid $colorLink;
    padding-left: 1rem;
    &-avtar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 2.25rem;
      aspect-ratio: 1;
      border-radius: 0.25rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-info {
      .names {
        display: block;
        color: #b6e5ff;
        font-size: 0.875rem;
        font-weight: 500;
      }
      .title {
        display: block;
        color: #b6e5ff;
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: 0.012em;
        opacity: 0.5;
        line-height: 1;
      }
    }
    .userdrpdwn-btn {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      position: relative;
      z-index: calc($zindex-dropdown + 1);
      cursor: pointer;
    }
    .userdrpdwn-menu {
      display: flex;
      flex-direction: column;
      border-radius: 0.25rem;
      padding: 0.5rem 0 0;
      position: absolute;
      right: 0;
      min-width: 11.5rem;
      top: calc(100% + 1rem);
      opacity: 0;
      pointer-events: none;
      transition: 300ms ease-in-out;
      transition-property: opacity, top, transform;
      z-index: $zindex-dropdown;
      .userdrpdwn-links {
        background-color: #0063cf;
        border-radius: 0.25rem;
        padding: 0.5rem 0 1rem;
      }
      .kbk-nav-link {
        display: flex;
        align-items: center;
        color: #def1ff;
        text-transform: capitalize;
        svg,
        img {
          width: 1.25rem;
          height: 1.25rem;
          flex-shrink: 0;
          margin-right: 0.25rem;
          fill: #2cbcff;
          .cls-1 {
            fill-opacity: 0;
          }
        }
        &:hover {
          opacity: 1;
          &::before {
            width: calc(100% - 2rem);
            opacity: 1;
          }
        }
      }
      hr {
        border-color: #005fad;
        margin: 0.5rem 1rem;
        opacity: 1;
      }
    }
    &:has(#ddwnMenu:checked),
    &:hover {
      .userdrpdwn-menu {
        top: 100%;
        opacity: 1;
        pointer-events: all;
        box-shadow: $boxshadow-setle;
      }
    }
    &:has(#ddwnMenu) {
      .userdrpdwn-menu {
        top: calc(100% + 1rem);
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
.kbk-userprofile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  background-color: #eff9ff;
  padding: 1.5rem;
  border-radius: $radius-sm;
  &-avtar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
    flex-shrink: 0;
    border-radius: 0.25rem;
    border: 2px solid #f1f5f9;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-info {
    .names {
      display: block;
      color: #0f172a;
      font-size: 1rem;
      font-weight: 500;
    }
    .title {
      display: block;
      color: #475569;
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: 0.012em;
      opacity: 0.5;
      line-height: 1;
    }
  }
}
.appli-content {
  position: relative;
  margin-top: calc(($overlap / 1.75) * -1);
  z-index: 3;
}

//mutilsteps forms
.stepper-content {
  background-color: #fff;
  border-radius: $radius;
  box-shadow: $boxshadow-setle;
  .stepper-header {
    display: flex;
    justify-content: flex-start;
    padding: 2rem $sidepad 1rem;
    .upper-counter {
      color: $curious-blue-400;
      text-transform: uppercase;
      font-size: 0.875rem;
      span {
        display: inline-block;
        padding: 0 0.5rem;
        background-color: $curious-blue-200;
        text-align: center;
        vertical-align: middle;
        border-radius: $radius-xs;
        font-size: $text-size-md;
        font-weight: 600;
        color: $blue-dark;
      }
    }
  }
  .stepper-body {
  }
}
.step-panel {
  display: flex;
  flex-direction: column;
  .track-profile {
    height: 5rem;
  }
  &_header {
    padding: 1rem $sidepad 2rem;
    border-bottom: 1px solid $slate-100;
    h2 {
      font-size: $text-size-lg;
    }
    form {
      flex: 1;
      max-width: 30rem;
    }
  }
  &_body {
    padding: 1rem $sidepad;
    form {
      // max-width: 50rem;
    }
    .result {
      display: flex;
      gap: 1.5rem;
      // margin-top: 2rem;
      h3 {
        margin-bottom: 1rem;
        color: $blue-dark;
        font-size: $text-size-md;
      }
      &-item {
        flex: 1;
      }
      &-map {
        // flex: 3;
        .map-wrapper {
          overflow: hidden;
          border: 1px solid rgba(0, 0, 0, 0.062);
          min-height: 18rem;
          aspect-ratio: 16/9;
        }
      }
    }
  }
  &_footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding: 1rem $sidepad $sidepad;
  }
}

//application dashboard
.app-dash {
  $c-padding: 1rem 1.5rem;
  .dash-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: 1rem;
    .app-l {
      flex: 1;
    }
    .app-main {
      flex: 3;
    }
  }
  .app-welnote {
    background-color: #005fad;
    border-radius: $radius-sm;
    min-height: 158px;
    position: relative;
    // padding: 4rem;
    overflow: hidden;
    box-shadow: $boxshadow-setle;
    &_dtails {
      $spacer: 2.5rem;
      position: absolute;
      left: $spacer;
      bottom: $spacer;
      font-size: 1rem;
      .prim-nt {
        display: block;
        font-size: 1em;
        color: #2cbcff;
      }
      h3 {
        font-size: 1.875em;
        color: #b6e5ff;
        font-weight: 200;
        span {
          display: inline-block;
          font-weight: 600;
          text-transform: capitalize;
        }
      }
      h4 {
        font-size: 1.125em;
        color: #ffcb2d;
        font-weight: 500;
        margin-top: 0.35em;
        span {
          display: inline-block;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
      @include media-breakpoint(sm) {
        $spacer: 1.5rem;
        left: $spacer;
        bottom: $spacer;
        font-size: 0.875rem;
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: url(/bg.svg) no-repeat;
      background-size: 50%;
      background-position: right center;
      z-index: 0;
      mix-blend-mode: multiply;
      opacity: 1;
      pointer-events: none;
    }
    @include media-breakpoint(sm) {
      min-height: 128px;
    }
  }
  .app-sysheader {
    background-color: #005fad;
    border-radius: $radius-sm;
    // min-height: 158px;
    position: relative;
    padding: 3rem 2.5rem 1.5rem;
    overflow: hidden;
    box-shadow: $boxshadow-setle;
    display: flex;
    // justify-content: space-between;
    // align-items: flex-end;
    flex-direction: column;
    gap: 1.25rem;
    .hder-sm {
      display: block;
      font-size: 0.875rem;
      color: #2cbcff;
    }
    .hder {
      font-size: 1.75rem;
      color: #b6e5ff;
      font-weight: 200;
      line-height: 1;
      // text-transform: uppercase;
    }
    .kbk-tabs {
      align-self: flex-end;
      flex-wrap: wrap;
      &-link {
        background-color: #005fad;
        border: 1px solid #0a91ed;
        color: #b6e5ff;
        height: 2rem;
        &:hover {
          background-color: #0071cb;
          border: 1px solid #34abfc;
        }
        &.active,
        &:active {
          background-color: #2cbcff;
          border-color: #2cbcff;
          color: #fff;
          &:hover {
            background-color: #2cbcff;
            border-color: #2cbcff;
            color: #fff;
          }
        }
      }
    }
  }
  .app-l {
    flex: 1;
    background-color: #fff;
    border-radius: $radius-sm;
    padding: 2rem;
    .progress-cards {
      margin-bottom: 2rem;
      .progress-title {
        display: block;
        font-size: 0.785rem;
        color: #2b2b2b;
        font-weight: 600;
      }
    }
    .progress-steps {
      .step-list {
        &_item {
          $pos-x: 2rem;
          $poz-y: 1.2rem;
          $poz-color: #f1f5f9;
          $poz-st-color: $blue-root;
          $point-size: 10px;
          position: relative;
          padding-left: $pos-x;
          padding-bottom: $poz-y;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0.25rem;
            display: block;
            width: $point-size;
            aspect-ratio: 1;
            border-radius: $radius-sm;
            border: calc($point-size / 4) solid $poz-st-color;
            background: $poz-color;
            z-index: 2;
          }
          &::after {
            content: "";
            position: absolute;
            left: calc($point-size / 2);
            transform: translateX(-50%);
            top: 0.25rem;
            display: block;
            width: 2px;
            height: 100%;
            border-radius: $radius-sm;
            background-color: $poz-color;
            z-index: 1;
          }
        }
        &_titl {
          margin-bottom: 0.2rem;
          h4 {
            font-size: 0.875rem;
            font-weight: 500;
          }
          h5{
            font-size: 0.75rem;
            font-weight: 500;
            color: $slate-500;
            line-height: 1.4;
            span{
              display: block;
              color: #2b2b2b;
              font-size: 0.875rem;
            }
          }
          &.step-ln{
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
          }
        }
        &_dscb {
          padding: 0.5rem 0.75rem;
          border-radius: $radius-sm;
          background-color: #f1f5f9;
          p {
            font-size: 0.75rem;
            font-weight: 500;
            opacity: 0.75;
            line-height: 1.45;
          }
        }
        &_item:nth-child(2) {
          &::before {
            border-color: #40d1ff;
          }
        }
        &_item:nth-child(3) {
          &::before {
            border-color: #6366f1;
          }
        }
        &_item:nth-child(4) {
          &::before {
            border-color: #a855f7;
          }
        }
        &_item:nth-child(5) {
          &::before {
            border-color: #d946ef;
          }
        }
        &_item:nth-child(6) {
          &::before {
            border-color: #ec4899;
          }
        }
        &_item:nth-child(7) {
          &::before {
            border-color: #14b2ff;
          }
        }
        &_item:nth-child(8) {
          &::before {
            border-color: #818cf8;
          }
        }
        &_item:nth-child(9) {
          &::before {
            border-color: #c084fc;
          }
        }
        &_item:last-child {
          padding-bottom: 0;
          &::before {
            border-color: #10b981;
          }
        }
      }
    }
  }
  .app-main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .app-stats {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      &_card {
        background-color: #fff;
        border-radius: $radius-sm;
        padding: 1rem 1.25rem;
        display: flex;
        flex: 1 0 14rem;
        box-shadow: $boxshadow-setle;
        border: 1px solid transparent;
        cursor: pointer;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          border-radius: $radius-xs;
          background-color: #faf5ff;
          flex-shrink: 0;
          img {
            width: 50%;
            object-fit: contain;
          }
        }
        .dtail {
          margin-left: 0.75rem;
          &-title {
            display: block;
            font-size: 0.75rem;
            font-weight: 400;
            opacity: 0.75;
          }
          &-num {
            display: block;
            font-size: 1.75rem;
            font-weight: 800;
            line-height: 1.2;
          }
        }
        &:hover {
          border: 1px solid #75d2ff;
          box-shadow: 0 0 0.5em 0.25em rgba(0, 37, 99, 0.025);
        }
      }
    }
    .app-lists {
      box-shadow: $boxshadow-setle;
      border-radius: $radius-sm;
      background-color: #fff;
      min-height: 200px;
      .app-tblist {
        &_title {
          padding: $c-padding;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          .hder {
            display: block;
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.8;
            letter-spacing: -0.25px;
          }
        }
        &_body {
          border-top: 3px solid #f1f5f9;
          padding-top: 2rem;
        }
      }
      .tblist {
        border-top: 3px solid #f1f5f9;
        overflow-x: auto;
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 99.8%;
          border: 1px solid #f1f5f9;
          border-inline-color: transparent;
          padding: 0.5rem 1rem;
          gap: 0.5rem;
          // border-radius: $radius-sm;
          &:nth-child(even){
            background-color: rgba($slate-50, 35%);
          }
          > .tblist-item_dt {
            flex: 1;
            padding: 0.25rem 0.5rem;
            white-space: wrap;
            vertical-align: middle;
            line-height: 1.2;
            span.ttl {
              display: block;
              font-size: 0.75rem;
              font-weight: 400;
              opacity: 0.5;
              white-space: nowrap;
            }
          }
          &_ck {
            max-width: fit-content;
          }
          &_icon {
            width: 3.5rem;
            max-width: 3.5rem;
            aspect-ratio: 1;
            overflow: hidden;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            border-radius: $radius-xs;
            background-color: #eff9ff;
            img {
              width: 65%;
              object-fit: center;
              &.icon-dft {
                display: block;
              }
              &.icon-done,
              &.icon-error,
              &.icon-wait {
                display: none;
              }
            }
            &.o_icon {
              background-color: #fff8ed;
            }
            &.icon-sm {
              width: 2.25rem;
              max-width: 2.25rem;
              aspect-ratio: 1;
            }
          }
          &_dt {
            display: block;
            font-size: 0.875rem;
            font-weight: 500;
            width: 100%;
            > span {
              margin-bottom: 0.375rem;
              display: block;
              width: fit-content;

              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 6;
              overflow: hidden;
            }
            // .ttclp{
            //   // width: 300px;
              
            // }
            &.txt-l {
              white-space: normal;
              flex: 2;
            }
            // white-space: nowrap;
          }
          &_status {
            // flex: auto;
            padding: 0 0.25rem;
            width: 6rem;
            display: flex;
            justify-content: center;
          }
          &_xcn {
            display: inline-flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex-direction: column;
            gap: 0.35rem;
            width: 8rem;
          }
          &_commnt {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            label {
              position: relative;
              display: flex;
              align-items: center;
              gap: 0.125em;
              color: #0099e8;
              font-size: 0.75em;
              font-weight: 500;
              cursor: pointer;
              padding: 0.25rem 0.75rem;
              border-radius: 0.25em;
              width: fit-content;
              border: 1px solid transparent;
              #ansLst {
                display: none;
              }
              &::after {
                content: "";
                display: block;
                width: 1em;
                aspect-ratio: 1;
                transform-origin: center;
                background-image: url("/assets/ikons/colored/ikon-arrowdown.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
                vertical-align: middle;
              }
              &:hover {
                background-color: #009be818;
              }
            }
          }
          &_ansl {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            > * {
              flex: 1;
              padding: 0.25rem 0.5rem;
              white-space: wrap;
              vertical-align: middle;
              line-height: 1.2;
              span.ttl {
                display: block;
                font-size: 0.75rem;
                font-weight: 400;
                opacity: 0.5;
              }
            }
          }
          &_commntlist {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            column-gap: 1rem;
            max-height: 0;
            overflow: hidden;
            border-radius: 0.5rem;
            padding: 0 1rem;
            border: solid #f1f5f9;
            background-color: #f1f5f988;
            border-width: 0px;
            .tblist-item_dt {
              flex: 1 0 auto;
              padding: 0.25rem 0.5rem;
              width: calc((100% - 2rem) / 2);
              white-space: wrap;
              margin-top: 1rem;
              .ttl {
                white-space: nowrap;
              }
              span.ttl {
                display: block;
                font-size: 0.75rem;
                font-weight: 400;
                opacity: 0.5;
              }
            }
          }
          &.valign {
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
          }
          &:has(#ansLst:checked) {
            label {
              border-color: #f1f5f9;
              background-color: #f1f5f988;
              &::after {
                transform: rotate(-180deg);
                transition: transform 300ms ease-in-out;
              }
            }
            .tblist-item_commntlist {
              max-height: 100vh;
              transition: max-height 1s ease-in-out;
              border-width: 1px;
            }
          }
        }
        &:last-child {
        }
        .no-rcrds-found {
          display: block;
          font-size: 0.875rem;
          font-weight: 500;
          width: 100%;
          text-align: center;
          opacity: 0.5;
          margin: 2rem 0;
        }
        li:has(.no-rcrds-found) {
          min-height: 20rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: none;
        }
      }
    }
    .app-cont {
      box-shadow: $boxshadow-setle;
      border-radius: $radius-sm;
      background-color: #fff;
      min-height: 200px;
      &_title {
        padding: $c-padding;
        display: flex;
        justify-content: space-between;
        .hder {
          display: block;
          font-size: 1.5rem;
          font-weight: 800;
          line-height: 1.2;
        }
      }
      &_list {
        border-top: 3px solid #f1f5f9;
        padding: $c-padding;
      }
    }
  }
}
.progr-bar {
  $bar-h: 1.25rem;
  margin-top: 0.5rem;
  width: 100%;
  height: $bar-h;
  background-color: #f1f5f9;
  border-radius: $radius-sm;
  &_item {
    display: block;
    width: 50%;
    height: 100%;
    background-color: #10b981;
    border-radius: $radius-sm;
    font-size: 0.65em;
    color: #fff;
    font-weight: 800;
    text-align: center;
  }
}
.req-card {
  padding: 1.25rem;
  border: 1px solid #f1f5f9;
  border-radius: 0.75rem;
  flex: 1;
  h3 {
    margin-left: 0;
    margin-bottom: 1rem;
    color: $blue-root;
  }
  .form-out {
    margin-bottom: 0.25rem;
    label {
      line-height: 1.2;
    }
    span {
      // padding: 0.5rem 0.75rem;
      // border-radius: $sm-radius;
      // background-color: #f1f5f9;
      // margin-top: 0.25rem;
      // text-transform: none;
      text-transform: capitalize;
    }
  }
}
@media only screen and (max-device-width: 478px) {
  .usermenu {
    $colorLink: $science-blue-900;
    .userprofile {
      &-info {
        display: none;
      }
    }
  }
}
@media only screen and (max-device-width: 768px) {
  .app-dash {
    $c-padding: 1rem 1.5rem;
    .app-sysheader {
      flex-wrap: wrap;
      .header {
        display: block;
      }
      .kbk-tabs {
        overflow-x: auto;
        .kbk-tabs-link {
          line-height: 1.2;
          white-space: nowrap;
        }
      }
    }
    .dash-flex {
      // flex-wrap: wrap;
      flex-direction: column;
      .app-l {
        order: 2;
      }
    }
  }
  .account-hder {
    background: var(--kbk-tu-surface-mian);
    &-content {
      .prim-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 10rem;
          height: 3rem;
          aspect-ratio: 1;
          img {
            width: auto;
            height: 2rem;
            object-fit: contain;
          }
        }
        .main-nav {
          display: none;
          z-index: 2040;
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
          &-list {
            transition: transform 300ms ease-in-out;
          }
        }
        .mob-menu {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          border: 1px solid #2cbcff;
          border-radius: 0.25rem;
          width: 2.125rem;
          height: 2.125rem;
          &:hover {
            background-color: #0099e8;
            border-color: #0099e8;
            color: #b6e5ff;
          }
        }
        .kbk-btn-outline {
          background-color: transparent;
          border-color: #2cbcff;
          color: #b6e5ff;
          &:hover {
            background-color: #0099e8;
            border-color: #0099e8;
            color: #b6e5ff;
          }
        }
        .kbk-btn-main {
          background-color: #def1ff;
          border-color: #def1ff;
          color: #0099e8;
          &:hover {
            background-color: #eff9ff;
            border-color: #eff9ff;
            color: #0099e8;
          }
        }
        &:has(#acbgMenu:checked) {
          .main-nav {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            position: fixed;
            top: 0px;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 37, 99, 0.5);
            margin: 0;
            &-list {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              gap: 2rem;
              background: var(--kbk-tu-surface-mian);
              padding: 1rem;
              width: 240px;
              position: relative;
              top: 0px;
              // left: calc(100% - 120px);
              // transform: translateX(-50%);
              .kbk-nav-link {
                height: auto;
                margin-bottom: 1rem;
                ::before {
                  content: "";
                  top: calc(100% - 1rem);
                }
              }
              height: 100vh;
              padding-top: 4rem;
              .kbk-btn-close {
                position: absolute;
                top: 1.25rem;
                right: 1.25rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: 1px solid #2cbcff;
                border-radius: 0.25rem;
                width: 2.125rem;
                height: 2.125rem;
              }
            }
            .kbk-x {
              flex-direction: column;
            }
            .kbk-x-e {
              flex-direction: column;
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }
  .usermenu {
    $colorLink: $science-blue-900;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    &-list {
      display: none;
    }
  }
}

.tblist {
  min-height: 25rem;
  .tblist-item {
    &:nth-last-child(-n + 1) {
      .kbk-table-dropdown {
        &_list {
          top: auto;
          bottom: 20%;
        }
        &:hover {
          .kbk-table-dropdown_list {
            top: auto;
            bottom: 0;
          }
        }
      }
    }
  }
}
