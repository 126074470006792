$input-color: $grey-dark;
$input-background-color: transparent;
$input-border-color: $grey-lighter;
$input-height: 32px;
$input-placeholder-color: $grey-light;

$input-hover-color: $grey;
$input-hover-border-color: $grey;

$input-focus-color: $grey-darker;
$input-focus-border-color: $blue;

$input-disabled-color: $grey-lighter;
$input-disabled-background-color: $white-ter;
$input-disabled-border-color: $white-ter;
$input-disabled-placeholder-color: $grey-lighter;

$input-arrow: $cyan;

$input-icon-color: $grey-lighter;
$input-icon-active-color: $grey-dark;

$input-radius: $radius-xs;





