.m-p {
  position: relative;
  .dropdown-navmenu {
    display: block;
    position: absolute;
    top: calc(100% + .75rem);
    pointer-events: none;
    opacity: 0;
    right: 0;
    background-color: #fff;
    border: none;
    border-radius: $radius-xs;
    overflow: hidden;
    min-width: 10rem;
    box-shadow: $boxshadow-tooltip;
    z-index: $zindex-offpage;
    &-list {
      padding: .5rem;
    }

    &-link {
      $linkcolor: #6366f1;
      display: flex;
      padding: 0.5rem 1rem;
      gap: 0.5rem;
      color: $linkcolor;
      position: relative;
      margin: 0;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 1px;
        width: 3px;
        height: 55%;
        transform-origin: center;
        transform: translateY(-50%) scaleY(0);
        background-color: $linkcolor;
        border-radius: 2px;
      }
      .icn {
        height: 2.5rem;
        aspect-ratio: 1;
        flex-shrink: 0;
        overflow: hidden;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border-radius: 0.25rem;
        background-color: rgba($linkcolor, 0.75);
        svg {
          display: block;
          height: 50%;
          width: 50%;
          object-fit: contain;
          fill: #fff;
          path{
            fill: inherit;
          }
        }
      }
      .sender {
        color: #334155;
        display: block;
        font-size: 0.75rem;
        font-weight: 400;
        white-space: nowrap;
        padding-left: 0.25rem;
      }
      .msg {
        display: block;
        font-size: 0.675rem;
        font-weight: 600;
        white-space: nowrap;
        text-transform: capitalize;
        color: inherit;
        background-color: rgba($linkcolor, 0.1);
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        margin-top: 0.25rem;
        line-height: 1.2;
      }
      &:hover {
        &::before {
          transform: translateY(-50%) scaleY(1);
        }
      }
    }
  }
  &:hover {
    .dropdown-navmenu {
      top: 100%;
      pointer-events: all;
      opacity: 1;
    }
  }
  .notf-w {
    $linkcolor: #fd7e14;
    color: $linkcolor;
    &::before {
      background-color: $linkcolor;
    }
    .icn {
      background-color: rgba($linkcolor, 0.75);
    }
    .msg {
      background-color: rgba($linkcolor, 0.1);
    }
  }
  .notf-n {
    $linkcolor: #db2777;
    color: $linkcolor;
    &::before {
      background-color: $linkcolor;
    }
    .icn {
      background-color: rgba($linkcolor, 0.75);
      svg {
        transform-origin: top center;
        // transform: rotate(30deg);
        animation: bellshake 1500ms cubic-bezier(.36,.07,.19,.97) infinite;
      }
    }
    .msg {
      background-color: rgba($linkcolor, 0.1);
    }
    // @keyframes bellshake {
    //   0% { transform: rotate(0); }
    //   15% { transform: rotate(9deg); }
    //   30% { transform: rotate(-9deg); }
    //   45% { transform: rotate(6deg); }
    //   60% { transform: rotate(-6deg); }
    //   75% { transform: rotate(2deg); }
    //   85% { transform: rotate(-2deg); }
    //   100% { transform: rotate(0); }
    // }
    @keyframes bellshake {
      0% { transform: rotate(-17deg); }
      5% { transform: rotate(17deg); }
      10% { transform: rotate(-14deg); }
      15% { transform: rotate(17deg); }
      20% { transform: rotate(0deg); }
      100% { transform: rotate(0deg); }
    }
  }
  .notf-g {
    $linkcolor: #10b981;
    color: $linkcolor;
    &::before {
      background-color: $linkcolor;
    }
    .icn {
      background-color: rgba($linkcolor, 0.75);
    }
    .msg {
      background-color: rgba($linkcolor, 0.1);
    }
  }
}

