.invbody {
  .invoice-paper {
    width: 210mm;
    height: 297mm;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 14mm;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    box-shadow: $boxshadow-setle;
    
    .page-header {
      margin-bottom: 3.5em;
      padding-bottom: 1em;
      .tittle {
        padding: 0.5em 0;
        margin-bottom: 1.5em;
        border-radius: 0.1em;
        h2 {
          color: #202020;
          font-weight: bold;
          text-align: left;
          font-size: 3em;
        }
      }

      .header {
        display: flex;
        justify-content: space-between;
        .logo {
          width: auto;
          height: 8em;
          display: flex;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .address {
          p {
            font-size: 14px;
            text-align: left;
            color: #202020;
            font-weight: normal;
            line-height: 1.45;
          }
        }
      }
    }

    .page-body {
      margin-bottom: 2em;
      .bdy-hder {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 2.5em;
        .clnt {
          .lb {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0.5em;

            label {
              display: block;
              font-size: 14px;
              text-align: right;
              color: #202020;
              font-weight: normal;
              line-height: 1;
              opacity: 0.75;
              margin-right: 0.5em;
            }

            p {
              display: block;
              font-size: 16px;
              text-align: right;
              color: #202020;
              font-weight: bold;
              line-height: 1.45;
            }
          }
        }

        .ntmnt {
          .nt {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 0.25em;

            label {
              display: block;
              height: 26px;
              font-size: 14px;
              color: rgb(32, 32, 32);
              font-weight: normal;
              line-height: 26px;
              opacity: 0.75;
              margin-right: 0.15em;
              padding: 0em 0.5em;
              background: #e2e8f0;
              border-radius: 0.2em;
              width: 76px;
            }

            .hlder {
              border: 1px solid #e2e8f0;
              border-radius: 0.2em;
              width: 200px;
              height: 26px;
              overflow: hidden;

              p {
                display: block;
                font-size: 14px;
                color: #202020;
                font-weight: bold;
                line-height: 26px;
                padding: 0em 0.5em;
              }

              input {
                height: 100%;
                width: 100%;
                display: block;
                font-size: 14px;
                color: #202020;
                font-weight: bold;
                line-height: 26px;
                padding: 0.2em 0.5em;

                &::placeholder {
                  display: block;
                  font-size: 14px;
                  color: rgb(32, 32, 32);
                  font-weight: bold;
                  line-height: 26px;
                }

                &:focus {
                  background-color: #eee;
                }
              }
            }
          }
        }
      }

      .bdy-bdy {
        table {
          border-collapse: collapse;
          width: 100%;
          tr {
            th {
              background: #e2e8f0;
              border: 1px solid #cbd5e1;
              font-weight: bold;
              font-size: 14px;
              color: rgb(32, 32, 32);
              padding: 0.25em 0.78em;
            }

            th:last-child {
              font-weight: bold;
              text-align: right;
              width: 160px;
            }

            td {
              border: 1px solid #cbd5e1;
              font-size: 14px;
              font-weight: normal;
              color: rgb(32, 32, 32);
              padding: 0.25em 0.78em;
            }

            td:last-child {
              font-weight: bold;
              text-align: right;
              width: 160px;
              padding: 0;

              p {
                display: block;
                font-size: 14px;
                text-align: right;
                color: rgb(32, 32, 32);
                font-weight: bold;
                padding: 0.25em 0.78em;
              }

              input {
                height: 100%;
                width: 100%;
                display: block;
                font-size: 14px;
                text-align: right;
                color: rgb(32, 32, 32);
                font-weight: bold;
                padding: 0.25em 0.78em;

                &::placeholder {
                  display: block;
                  font-size: 100%;
                  color: rgb(32, 32, 32);
                  font-weight: bold;
                  text-align: right;
                  line-height: 1.45;
                }

                &:focus {
                  background-color: #eee;
                }
              }
            }
          }

          tfoot {
            td {
              font-weight: bold;
              padding: 0.25em 0.78em;
            }

            td:last-child {
              padding: 0.25em 0.78em;
            }
          }
        }
      }
    }

    .page-footer {
      .mhs {
        width: 75%;
        margin-bottom: 3em;
        p {
          font-size: 14px;
          color: rgb(32, 32, 32);
          font-weight: normal;
          line-height: 1.45;
        }
      }

      .signw {
        display: flex;
        justify-content: space-between;

        .ftx {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 0.25em;

          label {
            display: block;
            font-size: 14px;
            text-align: right;
            color: rgb(32, 32, 32);
            font-weight: normal;
            line-height: 1.45;
            opacity: 0.75;
            margin-right: 0.5em;
          }

          p {
            display: block;
            font-size: 14px;
            text-align: right;
            color: rgb(32, 32, 32);
            font-weight: bold;
            line-height: 1.45;
          }
        }
      }

      .py {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .pybutn {
          margin: 0 0.5em;
          label {
            font-size: 12px;
            color: rgb(32, 32, 32);
            font-weight: 500;
            margin: 0;
          }

          .bntw {
            display: flex;
            align-items: center;

            .bntpy {
              // background-color: #004EA4;
              // border: 1px solid #004EA4;
              border: none;
              background: linear-gradient(41deg,#0099e8,#0063cf);
              cursor: pointer;
              border-radius: $radius-xs;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              // width: 86px;
              max-width: fit-content;
              margin: 0px;
              padding: 0.35rem;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                cursor: pointer;
                pointer-events: none;
              }
              &:hover {
                background-color: #0263ca;
                border-color: #0263ca;
                img{
                    transform: scale(1.05);
                }
              }
            }
            .mn {
              background-color: #f1f5f9;
            }

            // .kb {
            //   background-color: #f1f5f9;
            // }
          }
        }
      }
    }
  }
}
