// body {
//   background-color: var(--kbk-tu-body-background-color);
//   font-size: var(--kbk-tu-body-size);
//   font-family: var(--kbk-tu-body-family);
//   color: var(--kbk-tu-body-color);
//   -moz-osx-font-smoothing: grayscale;
//   -webkit-font-smoothing: antialiased;
//   min-width: var(--kbk-tu-body-min-width);
//   overflow-x: var(--kbk-tubody-overflow-x);
//   overflow-y: var(--kbk-tubody-overflow-y);
//   text-rendering: var(--kbk-tubody-rendering);
//   text-size-adjust: 100%;
// }

input,
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  align-items: center;
  border: 1px solid transparent;
  box-shadow: none;
  display: inline-flex;
  font-size: inherit;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: bottom;
  padding: 0;
  margin: 0;
  &:focus,
  &:active {
    outline: none;
  }
  &[disabled],
  fieldset[disabled] {
    cursor: not-allowed;
  }
}
input[button],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: inherit;
  font-family: inherit;
  line-height: 1.25;
  letter-spacing: inherit;
  outline: none;
  color: inherit;
  // vertical-align: bottom;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  &[disabled],
  fieldset[disabled] {
    cursor: not-allowed;
  }
  &:focus,
  &:active {
    outline: none;
  }
  svg,
  img {
    width: 1em;
    height: 1em;
    object-fit: contain;
    fill: inherit;
    stroke: inherit;
  }
}
a {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &[disabled],
  fieldset[disabled] {
    cursor: not-allowed;
  }
  &:focus,
  &:active {
    outline: none;
  }
  svg,
  img {
    width: 1em;
    height: 1em;
    object-fit: contain;
    fill: inherit;
    stroke: inherit;
  }
}
*,
*::after,
*::before {
  transition-property: border, background-color, opacity, color, top, bottom,
    left, right, scale, rotate, background, background-image, box-shadow, width, height, transform,
    max-height, min-height, max-width, text-decoration;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.container {
  max-width: 78rem;
  margin-inline: auto;
  padding: 0 1.25rem;
  @include media-breakpoint(sm){
    padding: 0 0.5rem;
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  html {
    background: none;
    padding: 0;
  }
  body {
    box-shadow: none;
    margin: 0;
  }
  span:empty {
    display: none;
  }
  .add,
  .cut {
    display: none;
  }
}

@page {
  margin: 0;
}