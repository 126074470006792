.pagecontainer {
  width: fit-content;
  margin: 0 auto;

  --blue: #008aca;
  --yellow: #ebe750;
  --green: #0e914f;
  --sun: #fbd546;
  --sect-space: 6mm;

  .pagesheet {
    height: 297mm;
    width: 210mm;
    color: #212529;
    margin: 0 auto;
    .frontpg,
    .backpg {
      background: #fffff7 url("/assets/imgs/kcpt-bd.svg") no-repeat;
      background-position: center;
      background-size: 100%;
      padding: 14mm;
      font-size: 16px;
      height: 100%;
    }

    .page-header {
      .main {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &_coa {
        height: 20mm;
        width: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .sep {
        width: 0.75mm;
        height: 20mm;
        background-color: #bfbfbf;
        margin: 0 9mm;
      }

      &_title {
        h1,
        h2 {
          text-transform: uppercase;
          line-height: 1.35;
          font-weight: 800;
        }

        h1 {
          font-size: 1.125em;
        }

        h2 {
          font-size: 0.875em;
        }
      }

      .centered {
        text-align: center;
      }

      &_address {
        flex: 1;
        text-align: right;
        font-size: 0.875em;

        p {
          line-height: 1.35;
          font-weight: 600;

          span {
            opacity: 0.75;
            font-weight: 400;
          }
        }
      }

      .border {
        display: block;
        margin-top: 4mm;

        &-b {
          display: block;
          width: 100%;
          height: 1.4mm;
          background: var(--blue);
        }

        &-y {
          display: block;
          width: 100%;
          height: 0.75mm;
          background: var(--yellow);
        }

        &-g {
          display: block;
          width: 100%;
          height: 0.75mm;
          background: var(--green);
        }
      }
    }

    .page-body {
      &_title {
        margin: 8mm 0;

        h3 {
          text-transform: uppercase;
          line-height: 1.35;
          font-weight: 800;
          font-size: 1em;
        }
      }

      &_content {
        p {
          font-weight: 400;

          span {
            display: block;
          }
        }

        article {
          margin-top: 6mm;

          h4 {
            text-transform: uppercase;
            line-height: 1.35;
            font-weight: 400;
            font-size: 1em;
            margin-bottom: 3mm;
          }

          ul {
            list-style: none;
            counter-reset: li;

            li {
              counter-increment: li;

              &::before {
                // margin-left: -50px;
                content: counter(li) "." " ";
              }

              &:has(span) {
                display: flex;

                span {
                  display: block;
                }

                span.grp {
                  margin-left: 0.5em;
                }
              }
            }
          }
        }
      }
    }

    .page-footer {
      padding-bottom: calc(var(--sect-space) * 2);

      &_dates {
        margin-top: var(--sect-space);
        display: flex;
        gap: 6em;

        p {
          font-size: 0.875em;

          strong {
            display: block;
          }
        }
      }

      &_done {
        margin-top: var(--sect-space);

        p {
          font-size: 0.875em;

          strong {
            font-size: 1.125em;
          }
        }
      }
    }

    .frontpg {
      display: flex;
      flex-direction: column;
      position: relative;

      .page-body {
        flex: 1;
      }

      .qcode {
        position: absolute;
        width: 30mm;
        height: 30mm;
        right: 14mm;
        bottom: 16mm;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        qr-code {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .backpg {
      .page-body {
        &_content {
          article {
            ol {
              list-style: none;

              li {
                position: relative;
                padding-left: 0.75em;
                margin-bottom: 0.25em;
                line-height: 1.45;

                &::before {
                  $size: 0.285em;
                  position: absolute;
                  left: 0;
                  top: 0.75em;
                  transform: translateY(-50%);
                  content: "";
                  display: inline-block;
                  width: $size;
                  height: $size;
                  border-radius: 50%;
                  background-color: #212529;
                }
              }
            }
          }
        }
      }
    }
  }
}

.pagelandscape {
  // width: fit-content;
  // margin: 0 auto;

  --blue: #008aca;
  --yellow: #ebe750;
  --green: #0e914f;
  --sun: #fbd546;
  --sect-space: 6mm;

  .pagesheet {
    // width: 297mm;
    // min-height: 210mm;
    color: #212529;
    // max-width: 100%;
    width: 29.46cm;
    margin: 0 auto;
    .frontpg,
    .backpg {
      background: #fffff7;
      // background-position: top left;
      // background-size: 29.46cm 20.79cm;
      padding: 6mm 12mm;
      font-size: 15px;
      width: 29.46cm;
      height: 20.79cm;
      box-sizing: border-box;
      position: relative;
      &::before{
        content: '';
        display: none;
        position: absolute;
        text-transform: uppercase;
        line-height: 1.35;
        font-weight: 800;
        font-size: 4em;
        text-align: center;
        letter-spacing: 0.15em;
        padding: 0.25em 0.5em;
        border-radius: 0.5em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        transform-origin: center;
        opacity: 0.15;
        z-index: 2;
      }
      .bgimg{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        z-index: 1;
        mix-blend-mode: multiply;
        opacity: 0.15;
        pointer-events: none;
      }
      .page-header, .page-body, .page-footer{
        position: relative;
        z-index: 3;
      }
    }

    .page-header {
      .main {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &_coa {
        height: 20mm;
        width: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .sep {
        width: 0.75mm;
        height: 20mm;
        background-color: #bfbfbf;
        margin: 0 9mm;
      }

      &_title {
        h1,
        h2 {
          text-transform: uppercase;
          line-height: 1.35;
          font-weight: 800;
        }

        h1 {
          font-size: 1.125em;
        }

        h2 {
          font-size: 0.875em;
        }
      }

      .centered {
        text-align: center;
      }

      &_address {
        flex: 1;
        text-align: right;
        font-size: 0.875em;

        p {
          line-height: 1.35;
          font-weight: 600;

          span {
            opacity: 0.75;
            font-weight: 400;
          }
        }
      }

      .border {
        display: block;
        margin-top: 4mm;

        &-b {
          display: block;
          width: 100%;
          height: 1.4mm;
          background: var(--blue);
        }

        &-y {
          display: block;
          width: 100%;
          height: 0.75mm;
          background: var(--yellow);
        }

        &-g {
          display: block;
          width: 100%;
          height: 0.75mm;
          background: var(--green);
        }
      }
    }

    .page-body {
      &_title {
        margin: 8mm 0;

        h3 {
          text-transform: uppercase;
          line-height: 1.35;
          font-weight: 800;
          font-size: 1em;
        }
      }

      &_content {
        p {
          font-weight: 400;

          span {
            display: block;
          }
        }

        article {
          margin-top: 6mm;

          h4 {
            text-transform: uppercase;
            line-height: 1.35;
            font-weight: 400;
            font-size: 1em;
            margin-bottom: 3mm;
          }

          ul {
            list-style: none;
            counter-reset: li;

            li {
              counter-increment: li;

              &::before {
                // margin-left: -50px;
                content: counter(li) "." " ";
              }

              &:has(span) {
                display: flex;

                span {
                  display: block;
                }

                span.grp {
                  display: flex;
                  gap: 1em;
                  margin-left: 0.5em;
                }
              }
            }
          }
        }
      }
    }

    .page-footer {
      padding-bottom: calc(var(--sect-space) * 1);
      display: flex;
      gap: 10em;

      &_dates {
        margin-top: var(--sect-space);
        display: flex;
        gap: 5em;
        order: 2;

        p {
          font-size: 0.875em;

          strong {
            display: block;
          }
        }
      }

      &_done {
        margin-top: var(--sect-space);

        p {
          font-size: 0.875em;

          strong {
            font-size: 1.125em;
          }
        }
      }
    }

    .frontpg {
      display: flex;
      flex-direction: column;
      position: relative;

      .page-body {
        // flex: 1;
      }

      .qcode {
        position: absolute;
        width: 30mm;
        height: 30mm;
        right: 14mm;
        bottom: 16mm;
        z-index: 3;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        qr-code {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .backpg {
      .page-body {
        &_content {
          article {
            ol {
              list-style: none;

              li {
                position: relative;
                padding-left: 0.75em;
                margin-bottom: 0.25em;
                line-height: 1.45;

                &::before {
                  $size: 0.285em;
                  position: absolute;
                  left: 0;
                  top: 0.75em;
                  transform: translateY(-50%);
                  content: "";
                  display: inline-block;
                  width: $size;
                  height: $size;
                  border-radius: 50%;
                  background-color: #212529;
                }
              }
            }
          }
        }
      }
    }
  }
}
.valid{
  .pagesheet{
    .frontpg, .backpg{
      position: relative;
      &::before{
        display: block;
        content: 'Valid Document';
        border: 0.25em solid #0e914f;
        color: #0e914f;
      }
    }
  }
}
.notvalid{
  .pagesheet{
    .frontpg, .backpg{
      position: relative;
      &::before{
        display: block;
        content: 'Invalid Document';
        border: 0.25em solid #fc224a;
        color: #fc224a;
      }
    }
  }
}
.app-gen-content{
  overflow-x: auto;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  html {
    background: none;
    padding: 0;
  }
  body {
    box-shadow: none;
    margin: 0;
  }
  span:empty {
    display: none;
  }
  .add,
  .cut {
    display: none;
  }
  .pagecontainer {
    display: none;
    .pagesheet {
      height: 297mm;
      width: 210mm;
    }
  }
  section {
    break-after: always;
    break-before: always;
  }
  @page {
    size: A4 landscape;
  }
}
