/* You can add global styles to this file, and also import other style files */
/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';
@import "~bootstrap/dist/css/bootstrap.min.css";


// body {
//   @include respond-to("small") {
//     background-color: rgb(156, 39, 39);
//   }
//   @include respond-to("medium") {
//     background-color: rgb(197, 151, 25);
//   }
//   @include respond-to("large") {
//     background-color: rgb(20, 185, 61);
//   }
//   @include respond-to("x-large") {
//     background-color: rgb(139, 31, 153);
//   }
// }


.modal-ap-stas {
    max-width: 90vw !important;
    background-color: rebeccapurple !important;
}



// snackbar
.success-snackbar {
    color: white;
    --mdc-snackbar-container-color: #059669;
    --mat-mdc-snack-bar-button-color: #ffffff;
    position: fixed !important;
    z-index: 99999999999 !important;
}

.error-snackbar {
    max-width: max-content !important;
    color: white;
    --mdc-snackbar-container-color: #da7171;
    --mat-mdc-snack-bar-button-color: #ffffff;
    position: fixed !important;
    z-index: 99999999999999999999999999999999999999999999999999999999999 !important;
    /* Use a high z-index to overlay other content */
}

.warning-snackbar {
    max-width: max-content !important;
    color: white;
    --mdc-snackbar-container-color: #e4c022;
    --mat-mdc-snack-bar-button-color: #ffffff;
    position: fixed !important;
    z-index: 99999999999999999999999999999999999999999999999999999999999 !important;
    /* Use a high z-index to overlay other content */
}

.light-success-snackbar {
    color: #155724;
    --mdc-snackbar-container-color: #d4edda;
    --mat-mdc-snack-bar-button-color: darkgreen;
}

.light-error-snackbar {
    color: #721c24;
    --mdc-snackbar-container-color: #f8d7da;
    border-color: #f5c6cb;
    --mat-mdc-snack-bar-button-color: darkred;
}


.estrx {
    color: darkred;
}

.no-rcrds-found {
    text-align: center;
    margin-top: 20px;
}


.disabled-select {
    pointer-events: none;
    opacity: 0.6;
    background-color: #f2f2f2;
}

@import "./assets/turacomini/turacomini";


text.highcharts-credits {
    // color: #133e4e !important;
    // font-size: 9px;
    fill: #ffffff !important;
  }