$button-color: $white !default;
$button-background-color: $blue-root !default;
$button-border-color: $blue-root !default;
$button-border-width: 1px !default;
$button-padding: 0.5rem 1rem;
$button-padding-sm: 0.25rem 0.5rem;

$button-hover-color: $white !default;
$button-hover-background-color: $blue-dark !default;
$button-hover-border-color: $blue-dark !default;

$button-focus-color: $white !default;
$button-focus-background-color: $blue-dark !default;
$button-focus-border-color: $blue-darker !default;

$button-active-color: $white !default;
$button-active-background-color: $blue-dark !default;
$button-active-border-color: $blue-darker !default;

$button-disabled-background-color: $curious-blue-200 !default;
$button-disabled-border-color: $curious-blue-200 !default;
$button-disabled-color: $curious-blue-600 !default;
$button-disabled-opacity: 0.5 !default;

//error
$button-error-color: $rose-50 !default;
$button-error-background-color: $rose-500 !default;
$button-error-border-color: $rose-500 !default;

$button-error-hover-color: $rose-50 !default;
$button-error-hover-background-color: $rose-600 !default;
$button-error-hover-border-color: $rose-600 !default;

//warn
$button-warn-color: $sunglow-50 !default;
$button-warn-background-color: $sunglow-500 !default;
$button-warn-border-color: $sunglow-500 !default;

$button-warn-hover-color: $sunglow-50 !default;
$button-warn-hover-background-color: $sunglow-600 !default;
$button-warn-hover-border-color: $sunglow-600 !default;

//success
$button-success-color: $emerald-50 !default;
$button-success-background-color: $emerald-500 !default;
$button-success-border-color: $emerald-500 !default;

$button-success-hover-color: $emerald-50 !default;
$button-success-hover-background-color: $emerald-600 !default;
$button-success-hover-border-color: $emerald-600 !default;

input[button],
button,
.kbk-btn {
  background-color: $button-background-color;
  border-color: $button-border-color;
  border-radius: $sm-radius;
  color: $button-color;
  height: $height;
  font-size: $font-size;
  letter-spacing: $letterspacing;
  padding: $button-padding;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  svg {
    fill: $button-color;
    path {
      fill: inherit !important;
      opacity: 0.75;
    }
  }
  img{
    width: 1rem;
  }
  &[disabled],
  fieldset[disabled] {
    background-color: $button-disabled-background-color;
    border-color: $button-disabled-border-color;
    color: $button-disabled-color;
    opacity: $button-disabled-opacity;
    &:hover {
      background-color: $button-disabled-background-color;
      border-color: $button-disabled-border-color;
      color: $button-disabled-color;
      opacity: $button-disabled-opacity;
    }
  }
  &:hover {
    background-color: $button-hover-background-color;
    border-color: $button-hover-border-color;
    color: $button-hover-color;
  }
  &:focus {
    background-color: $button-focus-background-color;
    border-color: $button-focus-border-color;
    color: $button-focus-color;
  }
  &:active {
    background-color: $button-active-background-color;
    border-color: $button-active-border-color;
    color: $button-active-color;
  }
  &.kbk-btn-sm {
    height: $sm-height;
    font-size: $sm-font-size;
    letter-spacing: $sm-letterspacing;
    border-radius: $sm-radius;
    padding: $button-padding-sm;
  }
  &.kbk-btn-lg {
    height: $lg-height;
    font-size: $lg-font-size;
    letter-spacing: $lg-letterspacing;
  }
  &.kbk-btn-sec {
    background-color: $blue-lighter;
    border-color: $blue-lighter;
    color: $blue-root;
    &:hover {
      background-color: $blue-light;
      border-color: $blue-light;
      color: $blue-root;
    }
  }
  &.kbk-btn-outline {
    background-color: transparent;
    border-color: $blue-light;
    color: $blue-root;
    &:hover {
      background-color: $blue-lighter;
      border-color: $blue-light;
      color: $blue-root;
    }
  }
  &.kbk-btn-error {
    background-color: $button-error-background-color;
    border-color: $button-error-border-color;
    color: $button-error-color;
    &:hover {
      background-color: $button-error-hover-background-color;
      border-color: $button-error-hover-border-color;
      color: $button-error-hover-color;
    }
    &:focus,
    &:active {
      background-color: $button-error-hover-background-color;
      border-color: $button-error-hover-border-color;
      color: $button-error-hover-color;
    }
  }
  &.kbk-btn-warn {
    background-color: $button-warn-background-color;
    border-color: $button-warn-border-color;
    color: $button-warn-color;
    &:hover {
      background-color: $button-warn-hover-background-color;
      border-color: $button-warn-hover-border-color;
      color: $button-warn-hover-color;
    }
    &:focus,
    &:active {
      background-color: $button-warn-hover-background-color;
      border-color: $button-warn-hover-border-color;
      color: $button-warn-hover-color;
    }
  }
  &.kbk-btn-success {
    background-color: $button-success-background-color;
    border-color: $button-success-border-color;
    color: $button-success-color;
    &:hover {
      background-color: $button-success-hover-background-color;
      border-color: $button-success-hover-border-color;
      color: $button-success-hover-color;
    }
    &:focus,
    &:active {
      background-color: $button-success-hover-background-color;
      border-color: $button-success-hover-border-color;
      color: $button-success-hover-color;
    }
  }
  &.kbk-btn-close {
    position: relative;
    background: url("./../../../assets/ikons/SVG/close.svg") no-repeat;
    background-position: center;
    background-size: 75%;
  }
}
.btns {
  display: flex;
  gap: 0.5rem;
  width: fit-content;
}
.kbk-nav-link {
  color: $grey-dark;
  height: $height;
  font-size: .875rem;
  letter-spacing: $letterspacing;
  padding: $button-padding;
  line-height: 100%;
  font-weight: 500;
  border-bottom: 1px solid transparent;
  position: relative;
  opacity: 0.85;
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 50%);
    display: block;
    width: 0%;
    height: 2px;
    border-radius: 2px;
    background-color: $curious-blue-400;
    opacity: 0.65;
  }
  &:hover {
    opacity: 1;
    &::before {
      width: 65%;
      opacity: 1;
    }
  }
}
.kbk-nav-item {
  &.active,
  &:active {
    .kbk-nav-link {
      color: #fff !important;
      opacity: 1;
      &::before {
        width: 75%;
        opacity: 1;
      }
    }
  }
}

.kbk-link {
  display: inline-block;
  color: #0099e8;
  font-size: 0.875rem;
  padding: 0.2rem 0.5rem;
  border-radius: $sm-radius;
  width: fit-content;
  img {
    width: 95%;
    object-fit: contain;
  }
  svg {
    fill: #0099e8;
  }
  &:hover {
    background-color: #eff9ff;
  }
}
.kbk-link-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  min-width: 1.75rem;
  border: 1px solid $blue-light;
  background-color: $blue-lighter;
  color: $blue-root;
  font-size: 0.75rem;
  font-weight: 500;
  white-space: nowrap;
  border-radius: 0.25rem;
  padding: 0 0.35rem;
  position: relative;
  cursor: pointer;
  transform: scale(1);
  margin-inline: 0.125rem;
  img,
  svg {
    width: 100%;
    height: 65%;
    object-fit: contain;
  }
  &:hover {
    background-color: $blue-light;
    border-color: $blue-light;
    color: $blue-root;
    // transform: scale(1.1);
  }
  // &::after {
  //   content: attr(data-kbk-tooltip);
  //   position: absolute;
  //   bottom: 100%;
  //   left: 50%;
  //   opacity: 0;
  //   padding: 0.25rem;
  //   transform: translateX(-50%);
  //   z-index: $zindex-tooltip;
  //   text-transform: capitalize;
  //   font-size: 0.565rem;
  //   font-weight: 500;
  //   white-space: nowrap;
  //   background-color: #1e293b;
  //   border-radius: 0.2rem;
  //   box-shadow: $boxshadow-tooltip;
  //   pointer-events: none;
  //   color: #f8fafc;
  // }
  // &:hover {
  //   &::after {
  //     bottom: 105%;
  //     left: 50%;
  //     opacity: 1;
  //   }
  // }
}
.kbk-tabs {
  display: flex;
  // border-bottom:1px solid $button-border-color;
  position: relative;
  // &::before{
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 100%;
  //   left: 0;
  //   right: 0;
  //   width: 100%;
  //   height: 1px;
  //   background-color: $button-border-color;
  //   z-index: 2;
  // }
  &-item {
    margin-left: -1px;
    position: relative;
    z-index: 0;
    margin-bottom: -1px;
    // &:first-child {
    //   margin-left: 0;
    //   .kbk-tabs-link {
    //     border-bottom-left-radius: $sm-radius;
    //     border-top-left-radius: $sm-radius;
    //   }
    // }
    // &:last-child {
    //   .kbk-tabs-link {
    //     border-bottom-right-radius: $sm-radius;
    //     border-top-right-radius: $sm-radius;
    //   }
    // }
    &:has(.active, :active) {
      z-index: 3;
      margin-bottom: -1px;
    }
  }
  &-link {
    background-color: #f8fafc;
    border: 1px solid #cbd5e1;
    border-radius: 0;
    color: #64748b;
    height: 1.875rem;
    font-size: $font-size;
    letter-spacing: $letterspacing;
    padding: 0.275rem 0.7rem;
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
    position: relative;
    z-index: 0;
    white-space: nowrap;
    &:hover {
      background-color: $blue-light;
      border: 1px solid $blue-light;
      color: $blue-root;
    }
    &.active,
    &:active {
      border-color: $button-border-color;
      color: $button-hover-color;
      color: $button-background-color;
      &:hover {
        background-color: #f8fafc;
      }
    }
  }
}
// .kbk-tabs-item:has(.active, :active) a{
//   background-color: red;
// }

.hs-tp {
  position: relative;
  &::after {
    content: attr(data-kbk-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    opacity: 0;
    padding: 0.25rem;
    transform: translateX(-50%);
    z-index: $zindex-tooltip;
    text-transform: capitalize;
    font-size: 0.565rem;
    font-weight: 500;
    white-space: nowrap;
    background-color: #1e293b;
    border-radius: 0.2rem;
    box-shadow: $boxshadow-tooltip;
    pointer-events: none;
    color: #f8fafc;
  }
  &:hover {
    &::after {
      bottom: 105%;
      left: 50%;
      opacity: 1;
      padding: 0.25rem;
      transition-delay: 1000ms;
    }
  }
}


.kbk-btn.active{
  background-color: $button-active-background-color;
  border-color: $button-active-background-color;
  color: $button-active-color;
}