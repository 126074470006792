.app-gen {
  $c-padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &-header {
    position: relative;
    &.genvline {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
    > .genline {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    // padding: 3rem 2.5rem 1.5rem;
    padding: $c-padding;
    overflow: hidden;
    border-radius: $radius-sm;
    box-shadow: $boxshadow-setle;
    background-color: #fff;
    .hder-sm {
      display: block;
      font-size: 0.875rem;
      color: $black-bis;
      font-weight: 400;
      opacity: 0.75;
      text-transform: capitalize;
    }
    .hder {
      font-size: 1.25rem;
      color: $black-bis;
      font-weight: 600;
      text-transform: capitalize;
      line-height: 1;
    }
    hr{
        margin: 0;
    }
  }
  &-content {
    padding: $c-padding;
    border-radius: $radius-sm;
    box-shadow: $boxshadow-setle;
    background-color: #fff;
    padding-bottom: 4rem;
  }
}

.appl-details {
  .track-profile {
    height: 5rem;
  }
  .land-info {
    display: flex;
    justify-content: space-between;
    flex: 1;
    gap: 2rem;
  }
  .aline {
    display: flex;
    gap: 1rem;
  }
  h3 {
    margin-bottom: 0.75rem;
    margin-left: 0.5rem;
    font-size: 0.875rem;
    color: $blue-root;
  }
  .form-out {
    label {
      display: block;
      font-size: 0.85rem;
      font-weight: 400;
      opacity: 0.75;
      line-height: 1;
    }
    span {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      text-transform: capitalize;
    }
    .bdg {
      font-size: 0.75rem;
      padding-inline: 1rem;
    }
    .progr-bar {
      width: 10rem;
      height: 1rem;
      margin-top: 0.5rem;
    }
  }
  .track-user {
    font-size: 1rem;
  }
  .track-usersub {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: capitalize;
    opacity: 1;
  }
}
.appl-info-card {
  padding: 1.25rem;
  border: 1px solid #f1f5f9;
  border-radius: 0.75rem;
  //   flex: 1;
  h3 {
    margin-left: 0;
  }
  .form-out {
    margin-bottom: 0.5rem;
    label {
      line-height: 1.2;
    }
    span {
      padding: 0.5rem 0.75rem;
      border-radius: $sm-radius;
      background-color: #f1f5f9;
      margin-top: 0.25rem;
      text-transform: none;
    }
  }
  &.fx-2 {
    flex: 2 !important;
  }
  .uploaded-file {
    background-color: #f1f5f9;
    padding: 1rem;
    img {
      width: 2.25rem;
      aspect-ratio: 1;
      margin-right: 0.5rem;
    }
    .kbk-x-s {
      span {
        display: block;
        opacity: 0.5;
        line-height: 1;
      }
    }
    .kbk-link {
      height: 1.875rem !important;
      width: auto;
      aspect-ratio: none;
      white-space: nowrap;
      display: inline-block;
      color: #64748b;
      background-color: #f8fafc;
      border: 1px solid #cbd5e1 !important;
      font-size: 0.785rem;
      margin-right: .5rem;
      svg {
        fill: #64748b;
      }
      &:hover {
        background-color: #def1ff;
        border: 1px solid #b6e5ff !important;
        color: #0099e8;
        svg {
          fill: #0099e8;
        }
      }
    }
  }
}
.appl-info-fx {
  flex-wrap: wrap;
  margin-bottom: 2rem;
  > * {
    min-width: 30rem;
    flex: 1;
  }
}
