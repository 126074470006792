.pg-chatroom {
  $c-padding: 1rem 1.5rem;
  display: flex;
  gap: 0.5rem;
  padding: 0 2rem;
  .chatlist {
    // background-color: #f8fafc;
    background-color: #fff;
    border-radius: $radius-sm;
    box-shadow: $boxshadow-setle;
    width: 24rem;

    &-hder {
      padding: $c-padding;
      .hder {
        display: block;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.8;
        letter-spacing: -0.25px;
        color: #b6e5ff;
        color: $black-bis;
      }
    }
    &-bdy {
      padding: 1rem;
      border-top: 1px solid $slate-100;
      height: calc(100vh - 25rem);
      overflow-y: auto;
      .contlist {
        &-item {
        }
        &-link {
          font-size: 1rem;
          color: $black-bis;
          padding: 0.75rem 0.75rem;
          margin-bottom: 0.125rem;
          border-radius: $radius-sm;
          display: flex;
          align-items: center;
          gap: 0.65rem;
          &:hover {
            background-color: $blue-lighter;
          }
          &.active {
            position: relative;
            background-color: $blue-lighter;
            &::after {
              content: "";
              position: absolute;
              display: block;
              top: 50%;
              transform: translateY(-50%);
              left: -1px;
              width: 2px;
              border: 1px;
              height: 50%;
              background-color: $blue-root;
            }
          }
          .user-avtar {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 2.25rem;
            width: 2.25rem;
            flex-shrink: 0;
            border-radius: 0.25rem;
            border: 1px solid $blue-lighter;
            background-color: $blue-lighter;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .user-txt {
            flex: 1;
            h6 {
              font-size: 0.875em;
              width: 100%;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              margin: 0;
            }
            p {
              font-size: 0.875em;
              opacity: 0.75;
              line-height: 1.4;
              width: 100%;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }
          .user-xn {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 0.2rem;
            .xn-dt {
              font-size: 0.75rem;
              font-weight: 600;
              opacity: 0.5;
              line-height: 1.2;
              width: 100%;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-align: right;
            }
            .xn-cnt {
              font-size: 0.75rem;
              font-weight: 600;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-align: center;
              width: 1.12rem;
              height: 1.12rem;
              border-radius: $radius-sm;
              background-color: $blue-root;
              color: #fff;
              vertical-align: middle;
              line-height: 1.4;
              flex-shrink: 0;
              margin-right: 0.35rem;
            }
          }
        }
      }
    }
  }
  .chatcontent {
    // background-color: #f8fafc;
    background-color: #fff;
    border-radius: $radius-sm;
    box-shadow: $boxshadow-setle;
    flex: 1;
    .chatwrap {
      display: flex;
      flex-direction: column;
      .chathder {
        padding: 1.5rem;
        font-size: 1rem;
        color: $black-bis;
        border-radius: $radius-sm;
        display: flex;
        align-items: center;
        gap: 0.85rem;
        .user-avtar {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 2.75rem;
          width: 2.75rem;
          flex-shrink: 0;
          border-radius: 0.25rem;
          border: 1px solid $blue-lighter;
          background-color: $blue-lighter;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .user-txt {
          flex: 1;
          h6 {
            font-size: 1em;
            width: 100%;
            line-height: 1.4;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            margin: 0;
          }
          .status {
            position: relative;
            display: flex;
            align-items: center;
            gap: 0.25rem;
            &::before {
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #366a9e;
              background-color: $emerald-500;
            }
            &::after {
              content: "Online";
              display: block;
              font-size: 0.875em;
              opacity: 0.75;
              line-height: 1.4;
              font-weight: 600;
            }
          }
          .online {
            &::before {
              content: "";
              background-color: $green;
            }
            &::after {
              content: "Online";
            }
          }
          .offline {
            &::before {
              content: "";
              background-color: $yellow;
            }
            &::after {
              content: "Left 1hr";
            }
          }
        }
        .user-xn {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 0.2rem;
          .xn-dt {
            font-size: 0.75rem;
            font-weight: 600;
            opacity: 0.5;
            line-height: 1.2;
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-align: right;
          }
          .xn-cnt {
            font-size: 0.75rem;
            font-weight: 600;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-align: center;
            width: 1.12rem;
            height: 1.12rem;
            border-radius: $radius-sm;
            background-color: $blue-root;
            color: #fff;
            vertical-align: middle;
            line-height: 1.4;
            flex-shrink: 0;
            margin-right: 0.35rem;
          }
        }
      }
      .chatbdy {
        padding: 0 1.5rem;
        .chatbox {
          // background-color: $blue-light;
          // background-color: #fff $blue-root;
          border-radius: $radius-sm;
          padding: 1rem;
          overflow-y: auto;
          height: calc(100vh - 23rem);
          background: $blue-lighter;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            inset: 0;
            background-image: url("/assets/ikons/logo/chatbg.svg");
            background-position: center;
            background-size: 12%;
            mix-blend-mode: color-burn;
            opacity: 0.75;
            z-index: 1;
          }
          &-bdy {
            position: relative;
            z-index: 2;
            .chatbox_msg {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              margin-bottom: 1rem;
              .msgbox {
                width: 70%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                &-txt {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  width: 100%;
                  p {
                    padding: 0.35rem 0.7rem;
                    width: fit-content;
                    margin-bottom: 0.12rem;
                    color: $black-bis;
                    background-color: #fff;
                    border-radius: 0.35rem;
                  }
                }
                &-tm {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  gap: 0.25rem;
                  margin: 0.25rem;
                  .tm,
                  .typ {
                    display: block;
                    font-size: 0.75rem;
                    opacity: 0.75;
                    line-height: 1;
                    font-weight: 500;
                    padding: 0.125rem;
                    text-transform: capitalize;
                  }
                }
              }
            }
            .outmsg {
              justify-content: flex-end;
              .msgbox {
                width: 70%;
                align-items: flex-end;
                &-txt {
                  align-items: flex-end;
                  p {
                    color: #fff;
                    background: var(--kbk-tu-surface-mian);
                  }
                }
                &-tm {
                  justify-content: flex-end;
                }
              }
            }
          }
        }
      }
      .chatfter {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        padding: $c-padding;
        .form-input {
          margin: 0;
          textarea {
            resize: none;
            min-height: 2.75rem;
            height: 2.75rem;
            margin: 0;
            box-sizing: border-box;
            padding: 0.5rem 0.75rem;
          }
        }
        .kbk-btn {
          width: 2.75rem;
          height: 2.75rem;
          margin: 0;
          padding: 0.45rem;
          display: flex;
          svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            .cls-1 {
              fill-opacity: 0;
            }
            .cls-2 {
              fill: $blue-dark !important;
            }
          }
        }
      }
    }
  }
}
.chat--container {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: $zindex-sticky;
  .chat--btn {
    background-color: $button-background-color;
    background: var(--kbk-tu-surface-chat);
    border-color: transparent;
    border-radius: $radius;
    color: $button-color;
    height: $lg-height;
    width: $lg-height;
    padding: 0.25rem;
    cursor: pointer;
    transform-origin: center;
    svg {
      width: 75%;
      height: 75%;
      fill: $button-color;
      path {
        fill: inherit !important;
        opacity: 0.75;
      }
    }
    .chatmsg {
      .cls-1,
      .cls-3 {
        fill: none;
      }
      .cls-1,
      .cls-2,
      .cls-3 {
        stroke: #fff;
        stroke-linejoin: round;
        stroke-width: 1.52px;
        opacity: 0.75;
      }
      .cls-2 {
        fill: none;
      }
      .cls-2,
      .cls-3 {
        stroke-linecap: round;
      }
    }
    &:hover {
      transform: scale(1.05);
      border-color: transparent;
    }
  }
  app-chat-bot {
    position: fixed;
    bottom: 4.5rem;
    right: 1.5rem;
    z-index: $zindex-sticky;
    box-shadow: $boxshadow-tooltip;
    border-radius: $radius-sm;
    overflow: hidden;
  }
}
.kbkchat {
  width: $width-xs;
  height: 34rem;
  background-color: $white;
  border-radius: $radius-sm;
  z-index: $zindex-sticky;
  box-shadow: $boxshadow-tooltip;
  padding: 0.25rem;
  background: var(--kbk-tu-surface-chat);
  // overflow: auto;
  position: relative;
  &-header {
    // background: var(--kbk-tu-surface-mian);
    padding: $space-1x;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: url(/bg.svg) no-repeat;
      background-size: 50%;
      background-position: right center;
      z-index: 0;
      mix-blend-mode: multiply;
      opacity: 0.25;
      pointer-events: none;
    }
    &-content {
      position: relative;
      z-index: 2;
      .kbkchat-userss {
        display: flex;
        justify-content: center;
        align-items: center;
        .kbkchat-profile {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 2.75rem;
          width: 2.75rem;
          border: 2px solid #f1f1f128;
          background-color: #f1f1f1;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .kbkchat-info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0.675rem 0;
        h3{
          font-size: 1.25rem;
          font-weight: 700;
          color: var(--kbk-tu-body-color);
          text-align: center;
        }
        .kbkchat-title {
          font-size: 0.875rem;
          font-weight: 700;
          color: var(--kbk-tu-body-color);
        }
        .kbkchat-status {
          font-size: 0.8rem;
          color: #b6e5ff;
          font-weight: 500;
          line-height: 1.2;
          &::before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $emerald-400;
            margin-right: 0.25em;
          }
        }
      }
    }
  }
  &-body {
    padding: $space-1x;
    flex: 1;
    background-color: var(--bs-body-bg);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-top-left-radius: $radius-sm;
    border-top-right-radius: $radius-sm;
    position: relative;
    z-index: 2;
    .kbkchat-message {
      // height: 87px;
      // height: 220px;
      overflow-y: auto;
      .kbkchat-message-box {
        font-size: 0.875rem;
        font-weight: 500;
        max-width: 75%;
        margin-bottom: 0.25em;
        .kbkchat-bubble {
          display: block;
          font-size: 0.875rem;
          line-height: 1.35;
          width: 100%;
          padding: 0.4rem 0.8rem;
          background-color: $slate-200;
          border-radius: $radius-sm;
          margin-bottom: 0.12rem;
        }
      }
      .kbkchat-message-form {
        font-size: 0.875rem;
        font-weight: 500;
        max-width: 75%;
        .kbkchat-input {
          input,
          select {
            background-color: $input-background-color;
            border-color: $input-border-color;
            color: $input-color;
            width: 100%;
            font-size: 0.875rem;
            font-weight: 400;
            height: 34px;
            font-size: $sm-font-size;
            letter-spacing: $sm-letterspacing;
            padding: $sm-padding;
            border-radius: $radius-sm;
            margin-bottom: 0.25em;
            &::placeholder {
              color: $input-placeholder-color;
              font-size: 0.75rem;
            }
            &:focus,
            &:active {
              border-color: $input-focus-border-color;
            }
          }
          select {
            appearance: none;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: center right 0.5em;
            background-size: 1em;
            padding-right: 2em;
          }
        }
      }
      .kbkchat-b {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0.25rem 0;
        strong{
          color: $science-blue-700;
          background: var(--kbk-tu-surface-chat);
          background: -webkit-linear-gradient(36deg,$science-blue-600,$science-blue-500);
          background-clip: text;
          -webkit-background-clip: text;
          text-fill-color: transparent;
          -webkit-text-fill-color: transparent;
        }
      }
      .kbkchat-u {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 0.25rem 0;
        .kbkchat-bubble {
          background-color: $science-blue-500;
          background: var(--kbk-tu-surface-chat);
          color: $curious-blue-50;
          border-radius: $radius-sm;
          // border-bottom-right-radius: 0;
          strong{
            color: $science-blue-700;
            background: var(--kbk-tu-surface-chat);
            background: -webkit-linear-gradient(36deg,$science-blue-600,$science-blue-500);
            background-clip: text;
            -webkit-background-clip: text;
            text-fill-color: transparent;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
    .kbkchat-message-static {
      // height: 336px;
      height: 220px;
      overflow-y: auto;
      .kbkchat-message-box {
        font-size: 0.875rem;
        font-weight: 500;
        max-width: 75%;
        margin-bottom: 0.25em;
        .kbkchat-bubble {
          display: block;
          font-size: 0.875rem;
          line-height: 1.35;
          width: 100%;
          padding: 0.4rem 0.8rem;
          background-color: $slate-200;
          border-radius: $radius-sm;
          margin-bottom: 0.12rem;
        }
      }
      .kbkchat-message-form {
        font-size: 0.875rem;
        font-weight: 500;
        max-width: 75%;
        .kbkchat-input {
          input,
          select {
            background-color: $input-background-color;
            border-color: $input-border-color;
            color: $input-color;
            width: 100%;
            font-size: 0.875rem;
            font-weight: 400;
            height: 34px;
            font-size: $sm-font-size;
            letter-spacing: $sm-letterspacing;
            padding: $sm-padding;
            border-radius: $radius-sm;
            margin-bottom: 0.25em;
            &::placeholder {
              color: $input-placeholder-color;
              font-size: 0.75rem;
            }
            &:focus,
            &:active {
              border-color: $input-focus-border-color;
            }
          }
          select {
            appearance: none;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: center right 0.5em;
            background-size: 1em;
            padding-right: 2em;
          }
        }
      }
      .kbkchat-b {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0.25rem 0;
        strong{
          color: $science-blue-700;
          background: var(--kbk-tu-surface-chat);
          background: -webkit-linear-gradient(36deg,$science-blue-600,$science-blue-500);
          background-clip: text;
          -webkit-background-clip: text;
          text-fill-color: transparent;
          -webkit-text-fill-color: transparent;
        }
      }
      .kbkchat-u {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 0.25rem 0;
        .kbkchat-bubble {
          background-color: $science-blue-500;
          background: var(--kbk-tu-surface-chat);
          color: $curious-blue-50;
          border-radius: $radius-sm;
          // border-bottom-right-radius: 0;
          strong{
            color: $science-blue-700;
            background: var(--kbk-tu-surface-chat);
            background: -webkit-linear-gradient(36deg,$science-blue-600,$science-blue-500);
            background-clip: text;
            -webkit-background-clip: text;
            text-fill-color: transparent;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
  &-footer {
    padding: $space-md $space-1x;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #f1f5f9;
    background-color: var(--bs-body-bg);
    input {
      background-color: $input-background-color;
      border-color: transparent;
      color: $input-color;
      width: 100%;
      font-size: 0.875rem;
      font-weight: 400;
      height: 2rem;
      font-size: $sm-font-size;
      letter-spacing: $sm-letterspacing;
      padding: 0;
      border-radius: 0;
      margin-bottom: 0;
      &::placeholder {
        color: $input-placeholder-color;
        font-size: 0.875rem;
      }
      &:focus,
      &:active {
        border-color: transparent;
      }
    }
    .send-btn {
      width: 2rem;
      height: 2rem;
      background-color: $slate-100;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      padding: 0;
      border-radius: 50%;
      transform-origin: center;
      overflow: hidden;
      svg {
        width: 75%;
        height: 75%;
        object-fit: contain;
        transform-origin: center;
        opacity: 1;
        .cls-1 {
          fill: none;
          stroke: none;
        }
        .cls-9 {
          fill: $slate-300;
        }
      }
      &:hover {
        transform: scale(1.05);
      }
    }
    &:has(input:focus) {
      .send-btn {
        background: var(--kbk-tu-surface-chat);
        svg {
          .cls-1 {
            fill: none;
          }
          .cls-9 {
            stroke: $curious-blue-50;
          }
        }
      }
    }
    .btns{
      display: flex;
      justify-content: center;
      width: 100%;
      button{
        background-image: var(--kbk-tu-surface-chat);
        border-color: transparent;
        width: 5rem;
      }
    }
  }
  &-int,
  &-convo {
    width: calc($width-xs - 0.5rem);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    .kbkchat-body {
      flex: 1;
    }
  }
  &-int{
    .kbkchat-body {
      // border-radius: calc($radius-sm - 0.25rem);
      .kbkchat-message{
        height: auto;
      }
      .kbkchat-message-form {
        font-size: 0.875rem;
        font-weight: 500;
        max-width: 85%;
        .kbkchat-input{
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  &-comb {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    width: calc((($width-xs - 0.5rem) * 2) + 0.5rem);
    height: 34rem - 0.5rem;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    transform: translateX(0rem);
    .stchbox{
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      width: 1.75rem;
      height: 1.75rem;
      border: 1px solid ;
      border-radius: $sm-radius;
      background-color: transparent;
      border-color: #2cbcff;
      color: #b6e5ff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 7;
      input{
        display: none;
      }
      svg{
        width: 75%;
        height: 75%;
        object-fit: contain;
        .cls-1 {
          fill-opacity: 0;
        }
        .cls-2 {
          fill: #b6e5ff;
        }
      }
      &:hover{
        background-color: $button-hover-background-color;
        border-color: transparent;
      }
    }
    &:has(input:checked){
      transform: translateX(-20rem);
      .kbkchat-convo{
        transform: scale(1);
      }
    }
  }
}

.chatlist {
  .kbk-tabs {
    background-color: #f8fafc;
    margin-top: 1rem;
    &-link {
      background-color: #f8fafc;
      border: none;
      border-bottom: 1px solid transparent;
      border-radius: 0;
      color: #64748b;
      height: 1.875rem;
      font-size: $font-size;
      letter-spacing: $letterspacing;
      padding: 0.275rem 0.7rem;
      font-weight: 500;
      text-transform: capitalize;
      cursor: pointer;
      position: relative;
      z-index: 0;
      white-space: nowrap;
      &:hover {
        border-bottom: $blue-light;
        color: $blue-root;
      }
      &.active,
      &:active {
        border-color: $button-border-color;
        color: $button-hover-color;
        color: $button-background-color;
        &:hover {
          background-color: #f8fafc;
        }
      }
    }
  }
}
