.slide-up {
  -webkit-animation: slide-up var(--timeslow)
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-up var(--timeslow) cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.stroke-l {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: strokegrow 10s var(--function) forwards;
}
.stroke-s {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: strokegrow 20s var(--function) forwards 300ms;
}
.dlay-s {
  animation-delay: 400ms;
}
.dlay-m {
  animation-delay: 500ms;
}
.dlay-l {
  animation-delay: 600ms;
}


@-webkit-keyframes slide-up {
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0px);
  }
}
@keyframes slide-up {
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes loadFading {
  0%,
  40%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}
@keyframes loadFading {
  0%,
  40%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}
@-webkit-keyframes loadSpin {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
@keyframes loadSpin {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
@keyframes strokegrow {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
