.ck-body {
  .kbk-x {
    gap: .5rem;
    .rnd-loc {
      flex: 2;
    }
    .rnd-use {
      flex: 1;
    }
  }
  .rnd-zon {
    margin-top: .5rem;
  }
}
.ck-note{
    margin-top: 3rem;
    padding: 0rem;
    h3{
        margin-bottom: 1rem;
    }
}
