@font-face {
    font-family: "Turaco-regular";
    font-style: normal;
    font-weight: normal;
    src: url("./../../fonts/Turaco-Regular.woff") format("woff");
  }
  
  @font-face {
    font-family: "Turaco-semibold";
    font-style: normal;
    font-weight: normal;
    src: url("./../../fonts/Turaco-SemiBold.woff") format("woff");
  }
  
  @font-face {
    font-family: "Turaco-bold";
    font-style: normal;
    font-weight: normal;
    src: url("./../../fonts/Turaco-Bold.woff") format("woff");
  }
  @font-face {
    font-family: "Turaco-black";
    font-style: normal;
    font-weight: normal;
    src: url("./../../fonts/Turaco-Black.woff") format("woff");
  }

  .display-md{
    font-size: 1.5rem;
  }